(function() {
  var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
templates['add_to_collection'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"==","playlist",{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":10,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<li tabindex=\"0\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"incollection") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":28},"end":{"line":9,"column":63}}})) != null ? stack1 : "")
    + "\" data-collection-uuid=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":9,"column":87},"end":{"line":9,"column":99}}}) : helper))) != null ? stack1 : "")
    + "\" data-toggable=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"toggable") || (depth0 != null ? lookupProperty(depth0,"toggable") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toggable","hash":{},"data":data,"loc":{"start":{"line":9,"column":116},"end":{"line":9,"column":130}}}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":9,"column":132},"end":{"line":9,"column":145}}}) : helper))) != null ? stack1 : "")
    + "</li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "selected";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"==","planning",{"name":"ifCond","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":18,"column":15}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--add-to-collection-wrapper\">\n	<div class=\"lp--add-to-collection lp--js--add-to-collection\">\n		<a href=\"#\" class=\"lp--closeBtn lp--js-close\" role=\"button\" title=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":69},"end":{"line":3,"column":89}}}))
    + "\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":103},"end":{"line":3,"column":123}}}))
    + "\"></a>\n		<h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"collection_add_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":37}}}))
    + "</h1>\n		<div class=\"lists\">\n			<ul data-section=\"playlist\" class=\"active\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"collections") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":3},"end":{"line":11,"column":12}}})) != null ? stack1 : "")
    + "				<li class=\"new\"><input autofocus type=\"text\" name=\"\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"collection_new_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":69},"end":{"line":12,"column":101}}}))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"collection_new_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":116},"end":{"line":12,"column":147}}}))
    + "\" /></li>\n			</ul>\n			<ul data-section=\"planning\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"collections") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":3},"end":{"line":19,"column":12}}})) != null ? stack1 : "")
    + "			</ul>\n		</div>\n		<a href=\"#\" class=\"save lp--js--add-to-collection-save\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_save",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":58},"end":{"line":22,"column":77}}}))
    + "</a>\n	</div>\n</div>\n";
},"useData":true});
templates['category_picker'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"lp--js-pickerHook\"></div>\n";
},"useData":true});
templates['chunk_footer'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<!-- Intentionally left empty -->";
},"useData":true});
templates['done_button'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<footer class=\"pr2--panelFooter\">\n  <div class=\"navigation-panel\">\n    <div class=\"lp--taskHandin-wrapper\">\n      <button type=\"button\" class=\"btn btn-default js-btn-done lp--taskHandin\">\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_done",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":27}}}))
    + "\n      </button>\n      <a href=\"#\" class=\"lp--taskHandin-resetText js-btn-undone\">\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_reset",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":28}}}))
    + "\n      </a>\n    </div>\n  </div>\n</footer>\n";
},"useData":true});
templates['edge_tools'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--addEdgeTools\">\n  <div class=\"lp--toolRow\">\n	  <a href=\"#\" data-state=\"visible\" class=\"tool-item lp--toolItem lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"edge_tool_visible",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":95},"end":{"line":3,"column":123}}}))
    + "\"  data-tooltip-ctx=\"lp--toolTipEdge\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"edge_tool_visible",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":161},"end":{"line":3,"column":189}}}))
    + "</a>\n    <a href=\"#\" data-state=\"optional\" class=\"tool-item lp--toolItem lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"edge_tool_optional",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":97},"end":{"line":4,"column":126}}}))
    + "\"  data-tooltip-ctx=\"lp--toolTipEdge\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"edge_tool_optional",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":164},"end":{"line":4,"column":193}}}))
    + "</a>\n    <a href=\"#\" data-state=\"delete\" class=\"tool-item lp--toolItem lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"edge_tool_delete",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":95},"end":{"line":5,"column":122}}}))
    + "\"  data-tooltip-ctx=\"lp--toolTipEdge\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"edge_tool_delete",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":160},"end":{"line":5,"column":187}}}))
    + "</a>\n  </div>\n</div>\n";
},"useData":true});
templates['flash'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--flash\" role=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":37}}}) : helper)))
    + "\">\n  <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":2,"column":5},"end":{"line":2,"column":18}}}) : helper))) != null ? stack1 : "")
    + "</p>\n  <a href=\"#\" class=\"lp--closeBtn lp--js-close\" role=\"button\" title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":69},"end":{"line":3,"column":89}}}))
    + "\" aria-label=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":103},"end":{"line":3,"column":123}}}))
    + "\"></a>\n</div>\n";
},"useData":true});
templates['invite_modal'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"invite_modal_goto_login",((stack1 = (depth0 != null ? lookupProperty(depth0,"current_user") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":62}}})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"invite_modal_goto_create_account",((stack1 = (depth0 != null ? lookupProperty(depth0,"current_user") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":71}}})) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"invite_modal_login_button",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":37},"end":{"line":23,"column":73}}}));
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"invite_modal_create_account",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":81},"end":{"line":23,"column":119}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--authenticateModal lp--inviteModal "
    + alias4(((helper = (helper = lookupProperty(helpers,"orgType") || (depth0 != null ? lookupProperty(depth0,"orgType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orgType","hash":{},"data":data,"loc":{"start":{"line":1,"column":50},"end":{"line":1,"column":61}}}) : helper)))
    + "\">\n  <div class=\"lp--authenticateModalBodyViewport\">\n    <a href=\"#\" class=\"lp--modalClose pr2--js-closeBtn\" title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":63},"end":{"line":3,"column":83}}}))
    + "\" aria-label=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":97},"end":{"line":3,"column":117}}}))
    + "\"></a>\n    <section class=\"lp--authenticateModalBody\">\n      <div class=\"lp--inviteModalHeader\">\n        <div class=\"lp--userAvatar\" style=\"background-image:url("
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"current_user") : depth0)) != null ? lookupProperty(stack1,"avatar") : stack1), depth0))
    + ")\"></div>\n        <h1>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"invite_modal_greeting",((stack1 = (depth0 != null ? lookupProperty(depth0,"current_user") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":63}}}))
    + "</h1>\n      </div>\n      <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":9,"column":9},"end":{"line":9,"column":26}}}) : helper))) != null ? stack1 : "")
    + "</p>\n      <div class=\"lp--formRow\">\n        <input class=\"lp--formSubmit lp--js-accept\" value=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_accept_invite",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":59},"end":{"line":11,"column":95}}}))
    + "\" type=\"button\" />\n      </div>\n      <p class=\"invite-logout-text\">\n        "
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"invite_modal_not_you",((stack1 = (depth0 != null ? lookupProperty(depth0,"current_user") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":59}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"existingUser") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "      </p>\n      <div class=\"lp--formRow\">\n        <input class=\"invitation-login-button\"\n          value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"existingUser") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":23,"column":17},"end":{"line":23,"column":126}}})) != null ? stack1 : "")
    + "\"\n          type=\"button\" />\n      </div>\n    </section>\n  </div>\n</div>";
},"useData":true});
templates['join_code'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "code-exists";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"user_missing") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":14,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"join-modal__login\">\n          <span class=\"join-modal__login-info\">"
    + alias1((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"join_modal_info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":47},"end":{"line":11,"column":74}}}))
    + "</span><br /><br />\n          <span class=\"join-modal__login-loop\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"codeToken") : depth0)) != null ? lookupProperty(stack1,"loopName") : stack1), depth0))
    + "</span>\n        </p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"8":function(container,depth0,helpers,partials,data) {
    return "text";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <p>\n            <span class=\"join-modal__email-header\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"join_modal_email_header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":51},"end":{"line":23,"column":85}}}))
    + "</span><br />\n            <span class=\"join-modal__email-body\">"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"join_modal_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":49},"end":{"line":24,"column":78}}})) != null ? stack1 : "")
    + "</span>\n          </p>\n          <div class=\"lp--formField\">\n            <input type=\"email\" class=\"lp--textInput lp--formInput lp--js-emailInput\" aria-required=\"true\" name=\"email\" id=\"email\"\n              aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":26},"end":{"line":28,"column":46}}}))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":61},"end":{"line":28,"column":81}}}))
    + "\" />\n          </div>\n          <div class=\"lp--formRow\">\n            <input class=\"lp--formSubmit\" value=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"join_modal_create_account",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":49},"end":{"line":31,"column":85}}}))
    + "\" type=\"submit\" />\n          </div>\n          <p class=\"join-modal__login\">\n            <span class=\"join-modal__login-header\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"join_modal_login_header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":51},"end":{"line":34,"column":85}}}))
    + "</span><br />\n            <span class=\"join-modal__login-body\">"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"join_modal_login_body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":49},"end":{"line":35,"column":83}}})) != null ? stack1 : "")
    + "</span>\n          </p>\n          <div class=\"lp--formRow\">\n            <input class=\"lp--formSubmit login-button\" value=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"join_modal_login_button",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":38,"column":62},"end":{"line":38,"column":96}}}))
    + "\" type=\"button\" />\n          </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"lp--formRow\">\n            <input class=\"lp--formSubmit\" value=\""
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"join_modal_action",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":42,"column":49},"end":{"line":42,"column":77}}}))
    + "\" type=\"submit\" />\n          </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--authenticateModal lp--authenticateModal-join-code "
    + alias4(((helper = (helper = lookupProperty(helpers,"orgType") || (depth0 != null ? lookupProperty(depth0,"orgType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orgType","hash":{},"data":data,"loc":{"start":{"line":1,"column":66},"end":{"line":1,"column":77}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"userClass") || (depth0 != null ? lookupProperty(depth0,"userClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":78},"end":{"line":1,"column":91}}}) : helper)))
    + "\">\n  <div class=\"lp--authenticateModalBodyViewport\">\n    <a href=\"#\" class=\"lp--modalClose pr2--js-closeBtn\" title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":63},"end":{"line":3,"column":83}}}))
    + "\" aria-label=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":97},"end":{"line":3,"column":117}}}))
    + "\"></a>\n    <div class=\"lp--authenticateModalBodyWrapper\">\n      <section class=\"lp--authenticateModalBody\">\n        <h1>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"join_modal_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":39}}}))
    + "</h1>\n        <p class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":18},"end":{"line":7,"column":48}}})) != null ? stack1 : "")
    + "\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"join_modal_body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":50},"end":{"line":7,"column":76}}}))
    + "</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"codeToken") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "        <form class=\"lp--form lp--js-form\" novalidate method=\"post\" action=\"/session/code\" autocomplete=\"on\">\n          <div class=\"lp--formField\">\n            <input class=\"lp--textInput lp--formInput\" aria-required=\"true\" id=\"signupcode\" aria-label=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"join_modal_code",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":104},"end":{"line":18,"column":130}}}))
    + "\" placeholder=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"join_modal_code",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":145},"end":{"line":18,"column":171}}}))
    + "\"\n              value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":19,"column":21},"end":{"line":19,"column":29}}}) : helper)))
    + "\" type=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":19,"column":37},"end":{"line":19,"column":74}}})) != null ? stack1 : "")
    + "\" />\n          </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_missing") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":21,"column":10},"end":{"line":44,"column":17}}})) != null ? stack1 : "")
    + "        </form>\n      </section>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
templates['login_modal'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":38}}}))
    + "</h1>\n      <p>"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_login_sign_in_with_microsoft_description",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":9},"end":{"line":6,"column":74}}})) != null ? stack1 : "")
    + "</p>\n      <button class=\"lp--teams-signin lp--js-teams-signin\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"sign_in_with_microsoft",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":59},"end":{"line":7,"column":92}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":15,"column":13}}})) != null ? stack1 : "")
    + "      <p class=\"lp--formErrorMessage\">\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_mismatch",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":39}}}))
    + "\n      </p>\n      <form class=\"lp--form lp--js-form\" novalidate method=\"post\" action=\"/login\" autocomplete=\"on\">\n        <input type=\"hidden\" name=\"code\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":20,"column":48},"end":{"line":20,"column":56}}}) : helper)))
    + "\" />\n        <div class=\"lp--formField\">\n          <input class=\"lp--textInput lp--formInput\" autofocus name=\"username\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":22,"column":86},"end":{"line":22,"column":95}}}) : helper)))
    + "\" type=\"email\"\n            aria-required=\"true\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":45},"end":{"line":23,"column":73}}}))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":88},"end":{"line":23,"column":116}}}))
    + "\" />\n        </div>\n        <div class=\"lp--formField\">\n          <input class=\"lp--textInput lp--formInput\" name=\"password\" type=\"password\"\n            aria-required=\"true\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":45},"end":{"line":27,"column":76}}}))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":91},"end":{"line":27,"column":122}}}))
    + "\" />\n          <span tabindex=\"0\" class=\"show-password-toggle\" role=\"img\" title=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"show_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":76},"end":{"line":28,"column":100}}}))
    + "\"></span>\n        </div>\n        <div class=\"lp--formRow lp--login-row-actions\">\n          <input class=\"lp--formSubmit\" value=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_action",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":47},"end":{"line":31,"column":76}}}))
    + "\" type=\"submit\" />\n          <a href=\"#\" class=\"lp--authenticatePasswordReminder lp--js-resetPwd\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_reset_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":79},"end":{"line":32,"column":116}}}))
    + "</a>\n        </div>\n        <div class=\"lp--external-identity\">\n          <h2>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_idp_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":14},"end":{"line":35,"column":46}}}))
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"signInWithMicrosoft") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":10},"end":{"line":40,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"signInWithFeide") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":10},"end":{"line":45,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"lp--formRow lp--alternative-action\">\n          <a href=\"#\" class=\"lp--authenticateSSOLogin lp--js-ssoLogin\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_sso",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":48,"column":71},"end":{"line":48,"column":97}}}))
    + "</a>\n          <a href=\"#\" class=\"lp--authenticatePasswordReminder lp--js-signupLink\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_create_account_action",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":49,"column":81},"end":{"line":50,"column":50}}}))
    + "</a>\n        </div>\n      </form>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <h1>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_accept_invite",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":10},"end":{"line":10,"column":46}}}))
    + "</h1>\n      <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":11,"column":9},"end":{"line":11,"column":26}}}) : helper))) != null ? stack1 : "")
    + "</p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":10},"end":{"line":13,"column":38}}}))
    + "</h1>\n      <p>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_login_description",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":9},"end":{"line":14,"column":49}}}))
    + "</p>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"lp--formRow\">\n            <button class=\"lp--teams-signin lp--js-microsoft-signin\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"sign_in_with_microsoft",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":38,"column":69},"end":{"line":38,"column":102}}}))
    + "</button>\n          </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"lp--formRow\">\n            <button class=\"lp--feide-signin lp--js-feide-signin\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"sign_in_with_feide",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":43,"column":65},"end":{"line":43,"column":94}}}))
    + "</button>\n          </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"new-in-loops lp--authenticateModal\">\n    <div class=\"new-in-loops__header\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_new_in_loops_header",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":58,"column":38},"end":{"line":58,"column":80}}}))
    + "</div>\n    <div class=\"new-in-loops__read-more\">"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_new_in_loops_read_more",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":59,"column":41},"end":{"line":59,"column":88}}})) != null ? stack1 : "")
    + "</div>\n    <div class=\"new-in-loops__button-wrapper\">\n      <button tabindex=\"0\" class=\"new-in-loops__button lp--formButton\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_new_in_loops_button",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":61,"column":71},"end":{"line":61,"column":113}}}))
    + "</button>\n    </div>\n  </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--authenticateModal lp--authenticateModal-login\">\n  <div class=\"lp--authenticateModalBodyViewport\">\n    <section class=\"lp--authenticateModalBody\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"signInWithTeams") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":53,"column":13}}})) != null ? stack1 : "")
    + "    </section>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"signInWithTeams") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":2},"end":{"line":64,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
templates['login_sso_modal'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <h1>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_sso_modal_accept_invite",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":50}}}))
    + "</h1>\n      <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"sso_description") || (depth0 != null ? lookupProperty(depth0,"sso_description") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"sso_description","hash":{},"data":data,"loc":{"start":{"line":6,"column":9},"end":{"line":6,"column":30}}}) : helper))) != null ? stack1 : "")
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_sso",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":36}}}))
    + "</h1>\n      <p>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_sso_modal_description",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":9},"end":{"line":9,"column":47}}}))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--authenticateModal lp--authenticateModal-login-sso\">\n  <div class=\"lp--authenticateModalBodyViewport\">\n    <section class=\"lp--authenticateModalBody\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sso_description") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":10,"column":13}}})) != null ? stack1 : "")
    + "      <p class=\"lp--formErrorMessage\">\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_modal_mismatch",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":39}}}))
    + "\n      </p>\n      <form class=\"lp--form lp--js-form\" novalidate method=\"post\" action=\"/login\" autocomplete=\"on\">\n        <input type=\"hidden\" name=\"sso\" value=\"true\" />\n        <input type=\"hidden\" name=\"code\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":16,"column":48},"end":{"line":16,"column":56}}}) : helper)))
    + "\" />\n        <div class=\"lp--formField\">\n          <input class=\"lp--textInput lp--formInput\" autofocus name=\"username\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":18,"column":86},"end":{"line":18,"column":95}}}) : helper)))
    + "\" type=\"email\"\n            aria-required=\"true\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":45},"end":{"line":19,"column":73}}}))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":88},"end":{"line":19,"column":116}}}))
    + "\" />\n        </div>\n        <div class=\"lp--formRow\">\n          <input class=\"lp--formSubmit\" value=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_sso_login_action",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":47},"end":{"line":22,"column":80}}}))
    + "\" type=\"submit\" />\n        </div>\n        <div class=\"lp--formRow lp--alternative-action\">\n          <a href=\"#\" class=\"lp--authenticatePasswordLogin lp--js-pwLogin\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_sso_login_with_email_and_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":75},"end":{"line":26,"column":55}}}))
    + "</a>\n        </div>\n      </form>\n    </section>\n  </div>\n</div>\n";
},"useData":true});
templates['naked_modal'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal "
    + alias4(((helper = (helper = lookupProperty(helpers,"animation") || (depth0 != null ? lookupProperty(depth0,"animation") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"animation","hash":{},"data":data,"loc":{"start":{"line":1,"column":18},"end":{"line":1,"column":31}}}) : helper)))
    + " naked\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":49}}}) : helper)))
    + "\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\" role=\"document\">\n 		<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"></button>\n  	<div class=\"modal-animation-wrapper\">\n  		"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":17}}}) : helper))) != null ? stack1 : "")
    + "\n	   </div>\n  </div>\n</div>";
},"useData":true});
templates['native_video_player'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<video controls poster=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"poster") || (depth0 != null ? lookupProperty(depth0,"poster") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"poster","hash":{},"data":data,"loc":{"start":{"line":1,"column":24},"end":{"line":1,"column":34}}}) : helper)))
    + "\" width=\"100%\">\n  <source src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"mp4") || (depth0 != null ? lookupProperty(depth0,"mp4") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mp4","hash":{},"data":data,"loc":{"start":{"line":2,"column":15},"end":{"line":2,"column":22}}}) : helper)))
    + "\" type=\"video/mp4\">\n</video>\n";
},"useData":true});
templates['node_locks'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--addNodeLocks\">\n  <div class=\"lp--toolRow\">\n    <a href=\"#\" data-state=\"unlocked\" class=\"tool-item lp--toolItem lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"node_tools_unlock",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":97},"end":{"line":3,"column":125}}}))
    + "\" data-tooltip-ctx=\"lp--toolTipArticle\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"node_tools_unlock",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":165},"end":{"line":3,"column":193}}}))
    + "</a>\n    <a href=\"#\" data-state=\"locked\" class=\"tool-item lp--toolItem lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"node_tools_lock",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":95},"end":{"line":4,"column":121}}}))
    + "\" data-tooltip-ctx=\"lp--toolTipArticle\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"node_tools_lock",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":161},"end":{"line":4,"column":187}}}))
    + "</a>\n    <div class=\"password-wrapper\">\n      <a href=\"#\" data-state=\"code\" class=\"tool-item lp--toolItem lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"node_tools_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":95},"end":{"line":6,"column":125}}}))
    + "\" data-tooltip-ctx=\"lp--toolTipArticle\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"node_tools_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":165},"end":{"line":6,"column":195}}}))
    + "</a>  \n      <div class=\"lp--nodePasswordLock lp--js-nodePasswordLock "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"lock_state") : depth0),"==","code",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":63},"end":{"line":7,"column":114}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"lp--nodePasswordLockAnimateWrapper\">\n          <input type=\"text\" title=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"node_tools_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":36},"end":{"line":9,"column":66}}}))
    + "\" autofocus class=\"lp--js-nodePasswordInput lp--nodePasswordInput\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":9,"column":140},"end":{"line":9,"column":148}}}) : helper)))
    + "\"/>\n          <a href=\"#\" data-state=\"close\" class=\"tool-item lp--toolItem\" title=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":79},"end":{"line":10,"column":99}}}))
    + "\"></a>  \n        </div>\n      </div>\n    </div>\n  </div>  \n</div>\n";
},"useData":true});
templates['node_tools'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--addNodeTools\">\n  <div class=\"lp--toolRow\">\n    <a href=\"#\" data-state=\"chunk\" class=\"lp--toolItem tool-item lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"chunk_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":94},"end":{"line":3,"column":115}}}))
    + "\" data-tooltip-ctx=\"lp--toolTipArticle\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"chunk_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":155},"end":{"line":3,"column":176}}}))
    + "</a>\n    <a href=\"#\" data-state=\"quiz\" class=\"lp--toolItem tool-item lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_quiz_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":93},"end":{"line":4,"column":122}}}))
    + "\" data-tooltip-ctx=\"lp--toolTipQuiz\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_quiz_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":159},"end":{"line":4,"column":188}}}))
    + "</a>\n    <a href=\"#\" data-state=\"task\" class=\"lp--toolItem tool-item lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_task_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":93},"end":{"line":5,"column":122}}}))
    + "\" data-tooltip-ctx=\"lp--toolTipTask\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_task_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":159},"end":{"line":5,"column":188}}}))
    + "</a>\n    <a href=\"#\" data-state=\"group\" class=\"lp--toolItem tool-item lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_group_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":94},"end":{"line":6,"column":124}}}))
    + "\" data-tooltip-ctx=\"lp--toolTipGroup\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_group_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":162},"end":{"line":6,"column":192}}}))
    + "</a>\n    <a href=\"#\" data-state=\"public_blog\" class=\"lp--toolItem tool-item lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_noticeboard_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":100},"end":{"line":7,"column":136}}}))
    + "\" data-tooltip-ctx=\"lp--toolTipPublicBlog\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_noticeboard_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":179},"end":{"line":7,"column":215}}}))
    + "</a>\n    <a href=\"#\" data-state=\"private_blog\" class=\"lp--toolItem tool-item lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_logbook_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":101},"end":{"line":8,"column":133}}}))
    + "\" data-tooltip-pos=\"bottom\" data-tooltip-ctx=\"lp--toolTipPrivateBlog\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_logbook_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":203},"end":{"line":8,"column":235}}}))
    + "</a>\n    <a href=\"#\" data-state=\"written_quiz\" class=\"lp--toolItem tool-item lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_written_quiz_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":101},"end":{"line":9,"column":138}}}))
    + "\" data-tooltip-pos=\"bottom\" data-tooltip-ctx=\"lp--toolTipQA\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_written_quiz_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":199},"end":{"line":9,"column":236}}}))
    + "</a>\n    <a href=\"#\" data-state=\"legend\" class=\"lp--toolItem tool-item lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_legend_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":95},"end":{"line":10,"column":126}}}))
    + "\" data-tooltip-pos=\"bottom\" data-tooltip-ctx=\"lp--toolTipLegend\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_legend_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":191},"end":{"line":10,"column":222}}}))
    + "</a>\n    <a href=\"#\" data-state=\"correlation\" class=\"lp--toolItem tool-item lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_correlation_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":100},"end":{"line":11,"column":136}}}))
    + "\" data-tooltip-pos=\"bottom\" data-tooltip-ctx=\"lp--toolTipCorrelation\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_correlation_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":206},"end":{"line":11,"column":242}}}))
    + "</a>\n    <a href=\"#\" data-state=\"evaluation\" class=\"lp--toolItem tool-item lp--js-tool-tip\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_evaluation_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":99},"end":{"line":12,"column":134}}}))
    + "\" data-tooltip-pos=\"bottom\" data-tooltip-ctx=\"lp--toolTipEvaluation\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"activity_evaluation_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":203},"end":{"line":12,"column":238}}}))
    + "</a>\n  </div>\n</div>\n";
},"useData":true});
templates['panel'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "lp--fullScreenPanel";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content-panel "
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":34}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":35},"end":{"line":1,"column":44}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fullscreen") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":45},"end":{"line":1,"column":89}}})) != null ? stack1 : "")
    + "\">\n  "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":15}}}) : helper))) != null ? stack1 : "")
    + "\n</div>";
},"useData":true});
templates['panel_header'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"pr2--panelHeaderSection\">\n  <div class=\"pr2--panelType lp--panelNavigation-activity lp--panelNavigationActivity-"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":2,"column":86},"end":{"line":2,"column":94}}}) : helper)))
    + "\">\n      <span class=\"lp--panelNavigationActivity-icon pr2--listIconWrapper-"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":3,"column":73},"end":{"line":3,"column":81}}}) : helper)))
    + "\"></span>\n      <p>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"titleKey") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":9},"end":{"line":4,"column":26}}}))
    + "</p>"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"teacher_panel_link"),depth0,{"name":"teacher_panel_link","hash":{"uuid":(depth0 != null ? lookupProperty(depth0,"uuid") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    </div>\n  </div>\n  <div class=\"pr2--panelHeaderTools\">\n    <span tabindex=\"0\" class=\"pr2--panelHeaderToolFullscreen lp--js-fullScreen\" role=\"img\" title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_full_screen",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":98},"end":{"line":8,"column":124}}}))
    + "\"></span>\n    <span tabindex=\"0\" class=\"pr2--panelHeaderToolImmersiveReader lp--js-immersiveReader\" role=\"img\" title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_open_screen_reader",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":108},"end":{"line":9,"column":141}}}))
    + "\"></span>\n    <span tabindex=\"0\" class=\"pr2--panelHeaderToolReadableFont\" role=\"img\" title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_dyslexic_font",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":82},"end":{"line":10,"column":110}}}))
    + "\"></span>\n    <span tabindex=\"0\" class=\"pr2--panelClose pr2--js-closeBtn\" role=\"img\" title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":82},"end":{"line":11,"column":102}}}))
    + "\"></span>\n  </div>\n</section>\n";
},"usePartial":true,"useData":true});
templates['password_panel'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"panel-content\">\n  <section class=\"pr2--panelLogoSection\">\n    <span tabindex=\"0\" class=\"pr2--panelClose pr2--js-closeBtn\" role=\"img\" title=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_close",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":82},"end":{"line":3,"column":102}}}))
    + "\"></span>\n  </section>\n  <header class=\"pr2--panelHeader\">\n    <h1 class=\"pr2--hubPaneName\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":41}}}) : helper)))
    + "</h1>\n  </header>\n  <section class=\"pr2--hubPanelDescription\">\n    <div class=\"content-viewer\">\n      "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"password_node_description",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":10,"column":42}}}))
    + " \n      <div class=\"lp--passwordPanelInputContainer\">\n        <input type=\"text\" class=\"lp--passwordPanelInput lp--js-passwordPanelInput\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"password_node_placeholder",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":96},"end":{"line":12,"column":132}}}))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"password_node_placeholder",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":147},"end":{"line":12,"column":183}}}))
    + "\" />\n      </div>\n    </div>\n  </section>\n  <section class=\"pr2--panelFooter\">\n    <div class=\"navigation-panel\">\n      <button class=\"pr2--panelSaveBtn lp--js-openBtn\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_continue",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":55},"end":{"line":18,"column":78}}}))
    + "</button>\n    </div>\n  </section>\n</section>\n";
},"useData":true});
templates['request_signup_modal'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--authenticateModal lp--authenticateModal-signup\">\n  <div class=\"lp--authenticateModalBodyViewport\">\n    <div class=\"lp--authenticateModalBodyWrapper\">\n      <section class=\"lp--authenticateModalBody\">\n        <div class=\"lp--authenticateModalBodyContainer\">\n          <h1>"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_get_account_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":6,"column":57}}})) != null ? stack1 : "")
    + "</h1>\n          <p>"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_get_account_body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":13},"end":{"line":7,"column":55}}})) != null ? stack1 : "")
    + "</p>\n          <form class=\"lp--form lp--js-form\" data-parsley-ui-enabled=\"true\" novalidate autocomplete=\"on\">\n            <div class=\"lp--formField\">\n              <input class=\"lp--textInput lp--formInput\" data-parsley-remote=\"/signup/validate_email\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":10,"column":109},"end":{"line":10,"column":118}}}) : helper)))
    + "\"\n                type=\"email\" aria-required=\"true\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":62},"end":{"line":11,"column":90}}}))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":105},"end":{"line":11,"column":133}}}))
    + "\" autofocus name=\"username\"\n                data-parsley-type-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_email_invalid",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":43},"end":{"line":12,"column":78}}}))
    + "\"\n                data-parsley-remote-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_email_taken",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":45},"end":{"line":13,"column":78}}}))
    + "\"\n                data-parsley-required-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_email_missing",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":47},"end":{"line":14,"column":82}}}))
    + "\" required />\n            </div>\n            <div class=\"lp--formRow\">\n              <input class=\"lp--formSubmit lp--js-requestSignup\" type=\"submit\"\n                value=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_signup_action",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":23},"end":{"line":18,"column":60}}}))
    + "\" />\n            </div>\n          </form>\n          <footer>\n            <p>"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_get_account_footer",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":15},"end":{"line":22,"column":59}}})) != null ? stack1 : "")
    + "</p>\n          </footer>\n        </div>\n      </section>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
templates['reset_password'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--authenticateModal lp--authenticateModal-reset-password\">\n\n  <div class=\"lp--authenticateModalBodyViewport\">\n    <div class=\"lp--authenticateModalBodyWrapper\">\n      <section class=\"lp--authenticateModalBody\">\n\n        <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_modal_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":49}}}))
    + "</h1>\n        <p>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_modal_body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":11},"end":{"line":8,"column":47}}}))
    + "</p>\n\n        <form class=\"lp--form lp--js-form\" novalidate method=\"post\" action=\"/reset_password\" autocomplete=\"on\">\n          <div class=\"lp--formField\">\n            <input class=\"lp--textInput lp--formInput lp--js-emailInput\" id=\"email\" name=\"email\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":12,"column":104},"end":{"line":12,"column":113}}}) : helper)))
    + "\"\n              type=\"email\" aria-required=\"true\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":60},"end":{"line":13,"column":88}}}))
    + "\" "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":90},"end":{"line":13,"column":118}}}))
    + "\" />\n          </div>\n          <div class=\"lp--formRow\">\n            <input class=\"lp--formSubmit\" value=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_modal_action",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":49},"end":{"line":16,"column":87}}}))
    + "\" type=\"submit\" />\n          </div>\n          <div class=\"lp--formRow lp--alternative-action\">\n            <a href=\"#\" class=\"lp--authenticatePasswordLogin lp--js-pwLogin\">\n              "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_sso_login_with_email_and_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":14},"end":{"line":20,"column":64}}}))
    + "\n            </a>\n          </div>\n        </form>\n      </section>\n\n      <section class=\"lp--authenticateModalBody lp--js-feedbackSection\">\n        <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_modal_feedback_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":58}}}))
    + "</h1>\n        <p>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_modal_feedback_body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":11},"end":{"line":28,"column":56}}}))
    + "</p>\n        <p>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_modal_feedback_no_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":29,"column":11},"end":{"line":29,"column":60}}}))
    + "\n          <a href=\"#\" class=\"reset-password__resend_email\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_modal_feedback_resend_email",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":30,"column":59},"end":{"line":30,"column":112}}}))
    + "</a>\n        </p>\n        <p>"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_modal_feedback_contact",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":11},"end":{"line":32,"column":61}}})) != null ? stack1 : "")
    + "</p>\n      </section>\n    </div>\n  </div>\n\n</div>\n";
},"useData":true});
templates['reset_password_confirm'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--authenticateModal lp--authenticateModal-reset-password-confirm\">\n\n  <div class=\"lp--authenticateModalBodyViewport\">\n    <div class=\"lp--authenticateModalBodyWrapper\">\n      <section class=\"lp--authenticateModalBody\">\n\n        <h2>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_confirm_modal_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":57}}}))
    + "</h2>\n        <p>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_confirm_modal_body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":11},"end":{"line":8,"column":55}}}))
    + "</p>\n\n        <form class=\"lp--form lp--js-form\" data-parsley-ui-enabled=\"true\" novalidate method=\"post\"\n          action=\"/reset_password/"
    + alias3(((helper = (helper = lookupProperty(helpers,"token") || (depth0 != null ? lookupProperty(depth0,"token") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"token","hash":{},"data":data,"loc":{"start":{"line":11,"column":34},"end":{"line":11,"column":45}}}) : helper)))
    + "\" autocomplete=\"off\">\n          <div class=\"lp--formField\">\n            <input class=\"lp--textInput lp--formInput lp--js-passwordInput\" id=\"password_input\" name=\"password\"\n              type=\"password\" aria-required=\"true\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":63},"end":{"line":14,"column":94}}}))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":109},"end":{"line":14,"column":140}}}))
    + "\" minlength=\"5\"\n              data-parsley-minlength-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_password_length",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":46},"end":{"line":15,"column":83}}}))
    + "\"\n              data-parsley-required-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_password_length",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":45},"end":{"line":16,"column":82}}}))
    + "\" required />\n              <span tabindex=\"0\" class=\"show-password-toggle\" role=\"img\" title=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"show_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":80},"end":{"line":17,"column":104}}}))
    + "\"></span>\n          </div>\n          <div class=\"lp--formRow\">\n            <input class=\"lp--formSubmit\" value=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_confirm_modal_action",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":49},"end":{"line":20,"column":95}}}))
    + "\" type=\"submit\" />\n          </div>\n        </form>\n      </section>\n\n      <section class=\"lp--authenticateModalBody lp--js-feedbackSection\">\n        <h2>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_confirm_modal_feedback_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":26,"column":66}}}))
    + "</h2>\n        <p>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"reset_password_confirm_modal_feedback_body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":11},"end":{"line":27,"column":64}}}))
    + "</p>\n        <div class=\"lp--formRow lp--alternative-action\">\n          <a href=\"#\" class=\"lp--authenticatePasswordLogin lp--js-pwLogin\">\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login_sso_login_with_email_and_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":30,"column":62}}}))
    + "\n          </a>\n        </div>\n      </section>\n    </div>\n  </div>\n\n</div>\n";
},"useData":true});
templates['rich_text_content'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "coms-not-supported";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a href=\"#\" class=\"lp--nodePaneComment lp--js-nodeComment\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"app_discuss",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":65},"end":{"line":7,"column":87}}}))
    + "</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <p class=\"italic\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"chunk_locked_desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":52}}}))
    + "</p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"done_button"),depth0,{"name":"done_button","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"panel-content chunk "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"requireSession") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":91}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"panel_header"),depth0,{"name":"panel_header","hash":{"titleKey":(depth0 != null ? lookupProperty(depth0,"titleKey") : depth0),"type":(depth0 != null ? lookupProperty(depth0,"type") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <header class=\"pr2--panelHeader\">\n    <h1 class=\"pr2--nodePaneName\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":44}}}) : helper)))
    + "</h1>\n    <div class=\"node-toolbar\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"noCommunication") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":8,"column":17}}})) != null ? stack1 : "")
    + "      <span role=\"button\" class=\"toggle-content\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"show_assignment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":49},"end":{"line":9,"column":75}}}))
    + "</span>\n    </div>\n  </header>\n  <section class=\"pr2--nodePanelBody\">\n    <div class=\"content-viewer\">\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":14,"column":6},"end":{"line":14,"column":21}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"locked") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "  </section>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"doable") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":2},"end":{"line":22,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/panel_navigation"),depth0,{"name":"behaviors/panel_navigation","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"chunk_footer"),depth0,{"name":"chunk_footer","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</section>\n";
},"usePartial":true,"useData":true});
templates['search_results'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"lp--librarySearch-loopResultImage\" style=\"background-image:url("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + ")\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--librarySearch-loopResult\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"image") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":4,"column":9}}})) != null ? stack1 : "")
    + "\n  "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":6,"column":11}}}) : helper)))
    + "\n</div>";
},"useData":true});
templates['signup_code'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--authenticateModal lp--authenticateModal-signup_code\">\n  <div class=\"lp--authenticateModalBodyViewport\">\n    <div class=\"lp--authenticateModalBodyWrapper\">\n      <section class=\"lp--authenticateModalBody\">\n        <div class=\"lp--authenticateModalBodyContainer\">\n          <h1>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_enter_code_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":6,"column":54}}}))
    + "</h1><br />\n          <p>"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_enter_code_body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":13},"end":{"line":7,"column":54}}})) != null ? stack1 : "")
    + "</p>\n          <p>"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_enter_code_resend_mail","<a href=\"#\" class=\"lp--js-resendMail\">","</a>",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":13},"end":{"line":8,"column":109}}})) != null ? stack1 : "")
    + "</p>\n          <p>"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_enter_code_contact",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":13},"end":{"line":9,"column":57}}})) != null ? stack1 : "")
    + "</p>\n        </div>\n      </section>\n    </div>\n  </div>\n</div>";
},"useData":true});
templates['signup_join_invites'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"lp--formField lp--invite\" tabindex=\"0\">\n              <div class=\"lp--formCheckboxLabel\">\n                <input type=\"checkbox\" id=\"invite_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":13,"column":50},"end":{"line":13,"column":56}}}) : helper)))
    + "\" checked=\"true\" name=\"invite_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":13,"column":86},"end":{"line":13,"column":92}}}) : helper)))
    + "\" required\n                  data-parsley-errors-container=\"#lp--authenticateModal-signup_join_invites--parsley-list\"\n                  data-parsley-multiple=\"invites\" data-parsley-mincheck=\"1\"\n                  data-parsley-required-message=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_join_invite_required",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":49},"end":{"line":16,"column":93}}}))
    + "\" />\n                <label for=\"invite_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":17,"column":35},"end":{"line":17,"column":41}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"organisation") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":18},"end":{"line":20,"column":25}}})) != null ? stack1 : "")
    + "                  <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"organisation") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":24,"column":27}}})) != null ? stack1 : "")
    + "                    <span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"session") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":26,"column":22},"end":{"line":34,"column":29}}})) != null ? stack1 : "")
    + "                    </span>\n                  </div>\n                </label>\n              </div>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <img class=\"organisation-icon\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"organisation") : depth0)) != null ? lookupProperty(stack1,"organisation") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"organisation") : depth0)) != null ? lookupProperty(stack1,"organisation") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"organisation") : depth0)) != null ? lookupProperty(stack1,"organisation") : stack1)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + "\" />\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"organisation") : depth0)) != null ? lookupProperty(stack1,"organisation") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "<br />\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signup_modal_join_invite_invited_by_loop",((stack1 = (depth0 != null ? lookupProperty(depth0,"invited_by") : depth0)) != null ? lookupProperty(stack1,"full_name") : stack1),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":22},"end":{"line":27,"column":94}}}))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"organisation") : depth0)) != null ? lookupProperty(stack1,"teacher") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":29,"column":22},"end":{"line":33,"column":29}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signup_modal_join_invite_invited_by_teacher",((stack1 = (depth0 != null ? lookupProperty(depth0,"invited_by") : depth0)) != null ? lookupProperty(stack1,"full_name") : stack1),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":30,"column":22},"end":{"line":30,"column":97}}}))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signup_modal_join_invite_invited_by",((stack1 = (depth0 != null ? lookupProperty(depth0,"invited_by") : depth0)) != null ? lookupProperty(stack1,"full_name") : stack1),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":22},"end":{"line":32,"column":89}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--authenticateModal lp--authenticateModal-signup_join_invites "
    + alias3(((helper = (helper = lookupProperty(helpers,"orgType") || (depth0 != null ? lookupProperty(depth0,"orgType") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"orgType","hash":{},"data":data,"loc":{"start":{"line":1,"column":76},"end":{"line":1,"column":87}}}) : helper)))
    + "\">\n  <div class=\"lp--authenticateModalBodyViewport\">\n    <div class=\"lp--authenticateModalBodyWrapper\">\n      <section class=\"lp--authenticateModalBody\">\n        <div class=\"lp--authenticateModalBodyContainer\">\n          <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_join_invite_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":6,"column":55}}}))
    + "</h1>\n          <p>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_join_invite_body",(depth0 != null ? lookupProperty(depth0,"userFullName") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":13},"end":{"line":7,"column":66}}}))
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"pending_token") : depth0)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "</p>\n          <form class=\"lp--form lp--js-form\" novalidate autocomplete=\"off\" data-parsley-ui-enabled=\"true\"\n            data-parsley-validate=\"\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"invites") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":40,"column":21}}})) != null ? stack1 : "")
    + "            <div class=\"lp--formRow\">\n              <div id=\"lp--authenticateModal-signup_join_invites--parsley-list\"></div>\n            </div>\n            <div class=\"lp--formRow justify-content\">\n              <input class=\"lp--formSubmit lp--js-persistSignup\" type=\"submit\"\n                value=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_join_invite_action",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":46,"column":23},"end":{"line":46,"column":65}}}))
    + "\" />\n              <div class=\"lp--alternative-action\">\n                <a href=\"#\" class=\"lp--js-createLink disabled\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_join_invite_create_new",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":48,"column":63},"end":{"line":48,"column":109}}}))
    + "</a><br />\n                <span class=\"parsley-style-error\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_join_invite_create_new_requirements",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":49,"column":50},"end":{"line":49,"column":109}}}))
    + "</span>\n              </div>\n            </div>\n          </form>\n        </div>\n      </section>\n    </div>\n  </div>\n</div>";
},"useData":true});
templates['signup_modal'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "invitation";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signup_modal_create_account_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":35},"end":{"line":6,"column":79}}}));
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signup_modal_create_organisation_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":87},"end":{"line":7,"column":54}}}));
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <p class=\"subtitle account\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signup_modal_create_account_subtitle",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":38},"end":{"line":9,"column":85}}}))
    + "</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":14,"column":59},"end":{"line":14,"column":72}}}) : helper)))
    + "\"";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"lastName") || (depth0 != null ? lookupProperty(depth0,"lastName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lastName","hash":{},"data":data,"loc":{"start":{"line":19,"column":57},"end":{"line":19,"column":69}}}) : helper)))
    + "\"";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"lp--formField\">\n              <input class=\"lp--textInput lp--formInput\" type=\"password\" aria-required=\"true\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":106},"end":{"line":24,"column":137}}}))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":152},"end":{"line":24,"column":183}}}))
    + "\"\n                name=\"password\" autocomplete=\"new-password\" minlength=\"5\"\n                data-parsley-required-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_password_length",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":47},"end":{"line":26,"column":84}}}))
    + "\"\n                data-parsley-minlength-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_password_length",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":48},"end":{"line":27,"column":85}}}))
    + "\" required autocomplete=\"none\" />\n              <span tabindex=\"0\" class=\"show-password-toggle\" role=\"img\" title=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"show_password",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":80},"end":{"line":28,"column":104}}}))
    + "\"></span>\n            </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"mobileNumber") || (depth0 != null ? lookupProperty(depth0,"mobileNumber") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"mobileNumber","hash":{},"data":data,"loc":{"start":{"line":33,"column":65},"end":{"line":33,"column":81}}}) : helper)))
    + "\"";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <br /><br />\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"signup_modal_organisation_form"),depth0,{"name":"signup_modal_organisation_form","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signup_modal_create_account_action",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":56,"column":108},"end":{"line":56,"column":153}}}));
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signup_modal_create_organisation_action",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":56,"column":161},"end":{"line":57,"column":55}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--authenticateModal lp--authenticateModal-signup_create "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInvitation") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":70},"end":{"line":1,"column":108}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"lp--authenticateModalBodyViewport\">\n    <div class=\"lp--authenticateModalBodyWrapper\">\n      <section class=\"lp--authenticateModalBody\">\n        <div class=\"lp--authenticateModalBodyContainer\">\n          <h1>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInvitation") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":7,"column":61}}})) != null ? stack1 : "")
    + "</h1><br />\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInvitation") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":10,"column":21}}})) != null ? stack1 : "")
    + "          <form class=\"lp--form lp--js-form\" data-parsley-ui-enabled=\"true\" novalidate>\n            <div class=\"lp--formField\">\n              <input class=\"lp--textInput lp--formInput\" autofocus type=\"text\" aria-required=\"true\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_first_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":112},"end":{"line":13,"column":145}}}))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_first_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":160},"end":{"line":13,"column":193}}}))
    + "\"\n                name=\"first_name\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"firstName") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":34},"end":{"line":14,"column":80}}})) != null ? stack1 : "")
    + " autocomplete=\"on\" required\n                data-parsley-required-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_first_name_required",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":47},"end":{"line":15,"column":88}}}))
    + "\" />\n            </div>\n            <div class=\"lp--formField\">\n              <input class=\"lp--textInput lp--formInput\" type=\"text\" aria-required=\"true\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_last_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":102},"end":{"line":18,"column":134}}}))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_last_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":149},"end":{"line":18,"column":181}}}))
    + "\"\n                name=\"last_name\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lastName") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":33},"end":{"line":19,"column":77}}})) != null ? stack1 : "")
    + " autocomplete=\"on\" required\n                data-parsley-required-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_last_name_required",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":47},"end":{"line":20,"column":87}}}))
    + "\" />\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"skipPassword") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":30,"column":23}}})) != null ? stack1 : "")
    + "            <div class=\"lp--formField\">\n              <input class=\"lp--textInput lp--formInput\" type=\"text\" aria-required=\"true\" aria-label=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_mobile_number",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":102},"end":{"line":32,"column":138}}}))
    + "\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeholder_mobile_number",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":153},"end":{"line":32,"column":189}}}))
    + "\"\n                name=\"mobile_number\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mobileNumber") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":37},"end":{"line":33,"column":89}}})) != null ? stack1 : "")
    + " autocomplete=\"on\" />\n            </div>\n            <div class=\"lp--mobileMarketing\">\n              "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_create_account_mobile_marketing",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":36,"column":14},"end":{"line":36,"column":69}}}))
    + "\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInvitation") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":12},"end":{"line":42,"column":23}}})) != null ? stack1 : "")
    + "\n            <br />\n            <div class=\"lp--formField lp--processingConsentField\">\n              <div class=\"lp--formCheckboxLabel\" tabindex=\"0\">\n                <input type=\"checkbox\" id=\"processing-consent-box\" name=\"processing_consent_given\" required\n                  data-parsley-checkmin=\"1\"\n                  data-parsley-required-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_missing_processing_consent",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":49,"column":49},"end":{"line":49,"column":97}}}))
    + "\" />\n                <label for=\"processing-consent-box\">\n                  "
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_agreement",(depth0 != null ? lookupProperty(depth0,"documentTag") : depth0),"</a>",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":51,"column":18},"end":{"line":51,"column":72}}})) != null ? stack1 : "")
    + "</label>\n              </div>\n            </div>\n\n            <div class=\"lp--formRow\">\n              <input class=\"lp--formSubmit lp--js-nextSignupStep\" type=\"button\" value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInvitation") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":56,"column":87},"end":{"line":57,"column":62}}})) != null ? stack1 : "")
    + "\" /><br />\n            </div>\n          </form>\n        </div>\n      </section>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});
templates['signup_modal_organisation'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--authenticateModal lp--authenticateModal-signup_create\">\n  <div class=\"lp--authenticateModalBodyViewport\">\n    <div class=\"lp--authenticateModalBodyWrapper\">\n      <section class=\"lp--authenticateModalBody\">\n        <div class=\"lp--authenticateModalBodyContainer\">\n          <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_create_organisation_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":6,"column":63}}}))
    + "</h1><br />\n          <form class=\"lp--form lp--js-form\" data-parsley-ui-enabled=\"true\" novalidate>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"signup_modal_organisation_form"),depth0,{"name":"signup_modal_organisation_form","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n            <div class=\"lp--formRow\">\n              <input class=\"lp--formSubmit lp--js-nextSignupStep\" type=\"button\" value=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_create_organisation_action",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":87},"end":{"line":11,"column":137}}}))
    + "\" /><br />\n            </div>\n          </form>\n        </div>\n      </section>\n    </div>\n  </div>\n</div>";
},"usePartial":true,"useData":true});
templates['signup_modal_organisation_form'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"subtitle organization\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_create_organisation_subtitle",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":85}}}))
    + "</p>\n<div class=\"lp--radioFormGroup country\">\n  <label id=\"countryLabel\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_country",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":27},"end":{"line":3,"column":58}}}))
    + "</label>\n  <div id=\"countryDropdownContainer\"></div>\n  <ul class=\"parsley-errors-list filled country-code-missing hidden\">\n    <li>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_country_required",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":46}}}))
    + "</li>\n  </ul>\n</div>\n<div class=\"lp--radioFormGroup organization-type\">\n  <label>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_create_organisation_type_label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":9},"end":{"line":10,"column":63}}}))
    + "</label>\n  <div class=\"lp--formRow\">\n    <div class=\"lp--formRadioButtonLabel school\" tabindex=\"0\">\n      <input type=\"radio\" name=\"organisation_type\" value=\"school\" id=\"org_type_school\" />\n      <label for=\"org_type_school\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_create_organisation_type_school",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":35},"end":{"line":14,"column":90}}}))
    + "</label>\n    </div>\n    <div class=\"lp--formRadioButtonLabel enterprise\" tabindex=\"0\">\n      <input type=\"radio\" name=\"organisation_type\" value=\"enterprize\" id=\"org_type_enterprize\" />\n      <label for=\"org_type_enterprize\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_create_organisation_type_enterprize",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":39},"end":{"line":18,"column":98}}}))
    + "</label>\n    </div>\n    <div class=\"lp--formRadioButtonLabel other\" tabindex=\"0\">\n      <input type=\"radio\" name=\"organisation_type\" value=\"other\" id=\"org_type_other\" />\n      <label for=\"org_type_other\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_create_organisation_type_other",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":34},"end":{"line":22,"column":88}}}))
    + "</label>\n    </div>\n  </div>\n  <ul class=\"parsley-errors-list filled organization-type-missing hidden\">\n    <li>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_organization_type_required",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":26,"column":56}}}))
    + "</li>\n  </ul>\n</div>\n<div class=\"lp--radioFormGroup organization-info\">\n  <div class=\"lp--formField municipality\">\n    <label id=\"municipalityLabel\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_placeholder_municipality",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":34},"end":{"line":31,"column":82}}}))
    + "</label>\n    <input aria-required=\"true\" aria-labelledby=\"municipalityLabel\" class=\"lp--textInput lp--formInput municipality\" type=\"text\" name=\"municipality\"\n      autocomplete=\"on\" required\n      data-parsley-required-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_municipality_required",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":37},"end":{"line":34,"column":80}}}))
    + "\" />\n  </div>\n  <div class=\"lp--formField organization-name\">\n    <label id=\"organisationLabel\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signup_modal_placeholder_organization_name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":37,"column":34},"end":{"line":37,"column":87}}}))
    + "</label>\n    <input aria-required=\"true\" aria-labelledby=\"organisationLabel\" class=\"lp--textInput lp--formInput organization-name\" type=\"text\" name=\"organisation_name\"\n      autocomplete=\"on\" required\n      data-parsley-required-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"form_error_organization_name_required",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":37},"end":{"line":40,"column":85}}}))
    + "\" />\n  </div>\n</div>\n<br />\n";
},"useData":true});
templates['static_activity_editor'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lp--activityEditorArea lp--js-editor\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":11,"column":54},"end":{"line":11,"column":62}}}) : helper)))
    + "</div>    \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"attachments") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"lp--activityEditorAttachments\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"attachments") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":6},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"lp--activityEditorAttachmentItem\">\n          <div class=\"lp--activityEditorAttachmentLabel\">\n            "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"filename") || (depth0 != null ? lookupProperty(depth0,"filename") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"filename","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":24}}}) : helper)))
    + "\n          </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"lp--activityEditorSection\">\n  <header class=\"lp--activityEditorSectionHeader lp--activityEditorSectionHeaderLogbook\">\n    <time>2018-01-02</time>\n    <div class=\"lp--activityEditorHeaderUser\">\n      <div class=\"lp--userAvatar\" style=\"background-image:url("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Participation") : depth0)) != null ? lookupProperty(stack1,"User") : stack1)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + ")\"></div>      \n      "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Participation") : depth0)) != null ? lookupProperty(stack1,"User") : stack1)) != null ? lookupProperty(stack1,"full_name") : stack1), depth0))
    + "\n    </div>\n  </header>\n  <div class=\"lp--activityEditor\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"BlogPosts") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</section>";
},"useData":true});
templates['teacher_panel_link'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "";
},"useData":true});
templates['teams_signup_select_path'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--authenticateModal lp--authenticateModal-teams-select-path\">\n  <div class=\"lp--authenticateModalBodyViewport\">\n    <div class=\"lp--authenticateModalBodyWrapper\">\n      <section class=\"lp--authenticateModalBody\">\n        <div class=\"lp--authenticateModalBodyContainer\">\n          <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"teams_signup_modal_select_path_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":6,"column":61}}}))
    + "</h1>\n\n          <div class=\"lp--formRow\">\n            <div>\n              <h2>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"teams_signup_modal_select_path_teacher_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":18},"end":{"line":10,"column":73}}}))
    + "</h2>\n              <p>"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"teams_signup_modal_select_path_teacher_body",(depth0 != null ? lookupProperty(depth0,"trialDays") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":17},"end":{"line":11,"column":83}}})) != null ? stack1 : "")
    + "</p>\n\n              <button class=\"lp--formSubmit lp--js-nextTeacher\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"teams_signup_modal_select_path_create_test_action",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":64},"end":{"line":13,"column":124}}}))
    + "</button>\n            </div>\n\n            <div>\n              <h2>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"teams_signup_modal_select_path_student_title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":18},"end":{"line":17,"column":73}}}))
    + "</h2>\n              <p>"
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"teams_signup_modal_select_path_student_body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":17},"end":{"line":18,"column":73}}})) != null ? stack1 : "")
    + "</p>\n\n              <button class=\"lp--formSubmit lp--js-nextStudent\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"teams_signup_modal_select_path_browse_loops",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":64},"end":{"line":20,"column":118}}}))
    + "</button>\n            </div>\n          </div>\n        </div>\n      </section>\n    </div>\n  </div>\n</div>\n";
},"useData":true});
templates['text_tools'] = template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\"#\" data-state=\"voiceover\" class=\"tool-item lp--toolItem lp--js-tool-tip\" data-tooltip-ctx=\"lp--toolTipEdge\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"text_tools_tooltip_readback",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":121},"end":{"line":4,"column":159}}}))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\"#\" data-state=\"comment\" class=\"tool-item lp--toolItem lp--js-tool-tip\" data-tooltip-ctx=\"lp--toolTipEdge\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"text_tools_tooltip_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":119},"end":{"line":7,"column":156}}}))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--textTools\">\n  <div class=\"lp--toolRow\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"vo") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"comment") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"useData":true});
templates['behaviors/header'] = template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <a href=\"#\" class=\"lp--nodePaneComment lp--js-nodeComment\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"app_discuss",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":61},"end":{"line":4,"column":83}}}))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"pr2--nodePaneName\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"node") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\n<div class=\"node-toolbar\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,(depth0 != null ? lookupProperty(depth0,"noCommunication") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":5,"column":13}}})) != null ? stack1 : "")
    + "  <span role=\"button\" class=\"toggle-content\">"
    + alias1((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias2,"show_assignment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":45},"end":{"line":6,"column":71}}}))
    + "</span>\n</div>\n";
},"useData":true});
templates['behaviors/hub_navigation'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <h2>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"panel_nav_in_this_hub",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":6},"end":{"line":3,"column":38}}}))
    + "</h2>\n  <ul class=\"lp--hubNavigation-activities nodes\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"lp--panelNavigation-activity node lp--panelNavigationActivity-"
    + alias4(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":7,"column":77},"end":{"line":7,"column":85}}}) : helper)))
    + " hub-panel-activity\">\n      <a href=\"#"
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":24}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":8,"column":26},"end":{"line":8,"column":129}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"completed") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "        "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":15,"column":16}}}) : helper)))
    + "\n      </a>\n      <div class=\"indicators\">\n        <div class=\"notification "
    + alias4(((helper = (helper = lookupProperty(helpers,"notification") || (depth0 != null ? lookupProperty(depth0,"notification") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"notification","hash":{},"data":data,"loc":{"start":{"line":18,"column":33},"end":{"line":18,"column":49}}}) : helper)))
    + "\"></div>\n        <div class=\"node-accepted "
    + alias4(((helper = (helper = lookupProperty(helpers,"accepted") || (depth0 != null ? lookupProperty(depth0,"accepted") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"accepted","hash":{},"data":data,"loc":{"start":{"line":19,"column":34},"end":{"line":19,"column":46}}}) : helper)))
    + "\"></div>\n      </div>\n    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":45},"end":{"line":8,"column":53}}}) : helper)))
    + "\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":67},"end":{"line":8,"column":75}}}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":8,"column":91},"end":{"line":8,"column":99}}}) : helper)))
    + "\" aria-label=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":8,"column":113},"end":{"line":8,"column":121}}}) : helper)))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div\n          class=\"lp--panelNavigationActivity-icon pr2--listIconWrapper-done pr2--listIconWrapper-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"type","hash":{},"data":data,"loc":{"start":{"line":11,"column":97},"end":{"line":11,"column":105}}}) : helper)))
    + " done\"></div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"lp--panelNavigationActivity-icon pr2--listIconWrapper-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"type","hash":{},"data":data,"loc":{"start":{"line":13,"column":74},"end":{"line":13,"column":82}}}) : helper)))
    + "\"></div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <h2>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"panel_nav_more_hubs",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":6},"end":{"line":27,"column":36}}}))
    + "</h2>\n  <ul class=\"lp--hubNavigation-activities hubs\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"neighbours") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":42,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"lp--panelNavigation-activity hub lp--panelNavigationActivity-legend pr2--listIconWrapper-home\">\n      <a href=\"#"
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":31,"column":24}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":31,"column":26},"end":{"line":31,"column":129}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"completed") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + "        "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":39,"column":16}}}) : helper)))
    + "\n      </a>\n    </li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"pr2--listIconWrapper-homeIcon lp--panelNavigationActivity-icon pr2--listIconWrapper-done\"\n          style=\"background-color: transparent;background-size: 14px;\"></div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"pr2--listIconWrapper-homeIcon pr2--listIconWrapper-"
    + alias4(((helper = (helper = lookupProperty(helpers,"lock_state") || (depth0 != null ? lookupProperty(depth0,"lock_state") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lock_state","hash":{},"data":data,"loc":{"start":{"line":36,"column":71},"end":{"line":36,"column":85}}}) : helper)))
    + "\"\n          style=\"background-image: url("
    + alias4(((helper = (helper = lookupProperty(helpers,"image") || (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":37,"column":39},"end":{"line":37,"column":48}}}) : helper)))
    + ")\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"pr2--hubPanelDescription lp--hubNavigation\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":24,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"neighbours") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":2},"end":{"line":44,"column":9}}})) != null ? stack1 : "")
    + "</section>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/panel_navigation"),depth0,{"name":"behaviors/panel_navigation","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
templates['behaviors/panel_navigation'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--panelNavigation\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"parent") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":13,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"next") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":2},"end":{"line":32,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"lp--panelNavigation-backColumn\">\n    <a tabindex=\"-1\" href=\"#"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"parent") : stack1)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "\">\n      <div class=\"lp--panelNavigation-activity lp--panelNavigationActivity-legend pr2--listIconWrapper-home\">\n        <span tabindex=\"0\" class=\"navigation-arrow navigation-arrow--up\" role=\"img\" title=\""
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"app_back_to_hub",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":91},"end":{"line":7,"column":117}}}))
    + "\"></span>\n        <div class=\"pr2--listIconWrapper-homeIcon\" style=\"background-image: url("
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"parent") : stack1)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + ")\"></div>\n        <span class=\"nav-node-name\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"parent") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"parent") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n      </div>\n    </a>\n  </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"lp--panelNavigation-nextColumn\">\n    <a tabindex=\"-1\" href=\"#"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"next") : stack1)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"next") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":21,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"next") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":26,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"nav-node-name\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"next") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"next") : stack1)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n        <span tabindex=\"0\" class=\"navigation-arrow navigation-arrow--right\" role=\"img\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"next") : stack1)) != null ? lookupProperty(stack1,"type") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":28,"column":87},"end":{"line":28,"column":198}}})) != null ? stack1 : "")
    + "></span>\n      </div>\n    </a>\n  </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"lp--panelNavigation-activity lp--panelNavigationActivity-article\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"lp--panelNavigation-activity lp--panelNavigationActivity-legend pr2--listIconWrapper-home\">\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"lp--panelNavigationActivity-icon pr2--listIconWrapper-"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"next") : stack1)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\"></span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"pr2--listIconWrapper-homeIcon\" style=\"background-image: url("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation") : depth0)) != null ? lookupProperty(stack1,"next") : stack1)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + ")\"></div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " title=\""
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"app_next_node",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":124},"end":{"line":28,"column":148}}}))
    + "\" ";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " title=\""
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"app_next_hub",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":166},"end":{"line":28,"column":189}}}))
    + "\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"navigation") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":34,"column":7}}})) != null ? stack1 : "");
},"useData":true});
templates['curriculum/editor'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"lp--curriculumEditorContainer\" tabindex=\"-1\">\n\n</div>";
},"useData":true});
templates['behaviors/correlation/footer'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<!-- Intentionally left empty -->";
},"useData":true});
templates['behaviors/correlation/viewer_single'] = template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"italic\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"correlation_locked_desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":26},"end":{"line":23,"column":60}}}))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"redoable lp--redoable\">\n          <br />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"redoable") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":27,"column":10},"end":{"line":31,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"correlation_redoable_desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":18},"end":{"line":28,"column":54}}}))
    + "</span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"correlation_not_redoable_desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":30,"column":18},"end":{"line":30,"column":58}}}))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"panel-content\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"panel_header"),depth0,{"name":"panel_header","hash":{"uuid":(depth0 != null ? lookupProperty(depth0,"uuid") : depth0),"titleKey":"activity_correlation_name","type":"correlation"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <header class=\"pr2--panelHeader\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/header"),depth0,{"name":"behaviors/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </header>\n  <section class=\"pr2--nodePanelBody\">\n    <div class=\"content-viewer\">\n      <div id=\""
    + alias1(container.lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"CorrelationSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"CorrelationReports") : stack1)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "\" class=\"student-behavior-menu\">\n      </div>\n\n      <div data-selection-entity=\"correlation\" data-selection-uuid=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":11,"column":68},"end":{"line":11,"column":76}}}) : helper)))
    + "\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":20}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n    <div class=\"legend-viewer\">\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/correlation/concept"),depth0,{"name":"behaviors/correlation/concept","hash":{"possible_answers":(depth0 != null ? lookupProperty(depth0,"possible_answers") : depth0),"answers_id":(depth0 != null ? lookupProperty(depth0,"answers_id") : depth0),"answers_body":(depth0 != null ? lookupProperty(depth0,"answers_body") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <div class=\"correlation-result\">\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"locked") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":22,"column":6},"end":{"line":33,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </section>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/panel_navigation"),depth0,{"name":"behaviors/panel_navigation","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/correlation/footer"),depth0,{"name":"behaviors/correlation/footer","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</section>\n";
},"usePartial":true,"useData":true});
templates['behaviors/evaluation/footer'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<!-- Intentionally left empty -->";
},"useData":true});
templates['behaviors/evaluation/static_activity_editor'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lp--activityEditorAttachments "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":16,"column":46},"end":{"line":16,"column":57}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"attachments") : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":25,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":18,"column":13},"end":{"line":18,"column":20}}}) : helper)))
    + "\" target=\"_blank\">\n      <div class=\"lp--activityEditorAttachmentItem\">\n        <div class=\"lp--activityEditorAttachmentLabel\">\n          "
    + alias4(((helper = (helper = lookupProperty(helpers,"filename") || (depth0 != null ? lookupProperty(depth0,"filename") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filename","hash":{},"data":data,"loc":{"start":{"line":21,"column":10},"end":{"line":21,"column":22}}}) : helper)))
    + "\n        </div>\n      </div>\n      </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--blogPostListItem lp--js-blogPostItem\" data-post-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":1,"column":70},"end":{"line":1,"column":78}}}) : helper)))
    + "\">\n<section class=\"lp--activityEditorSection\">\n    <header class=\"lp--activityEditorSectionHeader\">\n      <div class=\"lp--userAvatar\" style=\"background-image:url("
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + ")\"></div>\n      "
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"editor_title_evaluation",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":40}}}))
    + "\n      <div class=\"lp--activityEditorHeaderTools\">\n        <a href=\"#\" class=\"lp--activityEditorHeaderComment lp--js-triggerComment\" data-post-uuid=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "\" title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":122},"end":{"line":7,"column":144}}}))
    + "\" aria-label=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":158},"end":{"line":7,"column":180}}}))
    + "\"></a>\n      </div>\n    </header>\n  <div class=\"lp--activityEditor "
    + alias4(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":44}}}) : helper)))
    + "\">\n    <div class=\"lp--activityEditorArea "
    + alias4(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":11,"column":39},"end":{"line":11,"column":50}}}) : helper)))
    + " "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"state") : stack1), depth0))
    + "\" data-selection-entity=\"evaluationreport\" data-selection-uuid=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "\">"
    + ((stack1 = alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"body") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n    <div class=\"work-in-progress\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"work_in_progress",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":34},"end":{"line":12,"column":63}}}))
    + "</div>\n    <div tabindex=\"0\" class=\"sneak-peak\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"sneak_peak",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":41},"end":{"line":13,"column":64}}}))
    + "</div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"attachments") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":27,"column":9}}})) != null ? stack1 : "")
    + "</section>\n</div>";
},"useData":true});
templates['behaviors/evaluation/viewer'] = template({"1":function(container,depth0,helpers,partials,data) {
    return "coms-not-supported";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"deadline-wrapper\">\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"EvaluationSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1), depth0))
    + "\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"panel-content "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"requireSession") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":30},"end":{"line":1,"column":85}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"panel_header"),depth0,{"name":"panel_header","hash":{"uuid":(depth0 != null ? lookupProperty(depth0,"uuid") : depth0),"titleKey":"activity_evaluation_name","type":"evaluation"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <header class=\"pr2--panelHeader\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/header"),depth0,{"name":"behaviors/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"EvaluationSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "  </header>\n  <section class=\"pr2--nodePanelBody\">\n    <div class=\"content-viewer\" data-selection-entity=\"evaluation\" data-selection-uuid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":12,"column":88},"end":{"line":12,"column":96}}}) : helper)))
    + "\">\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":13,"column":18}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n  </section>\n  <section class=\"pr2--nodePanelBody\">\n    <div class=\"lp--curriculumEditorContainer\"></div>\n  </section>\n  <section class=\"lp--nodePanelUserSection\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/evaluation/user_section"),depth0,{"name":"behaviors/evaluation/user_section","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </section>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/evaluation/footer"),depth0,{"name":"behaviors/evaluation/footer","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</section>\n";
},"usePartial":true,"useData":true});
templates['behaviors/evaluation/viewer_single'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"deadline-wrapper\">\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"EvaluationSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1), depth0))
    + "\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"panel-content\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"panel_header"),depth0,{"name":"panel_header","hash":{"uuid":(depth0 != null ? lookupProperty(depth0,"uuid") : depth0),"titleKey":"activity_evaluation_name","type":"evaluation"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <header class=\"pr2--panelHeader\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/header"),depth0,{"name":"behaviors/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"EvaluationSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "  </header>\n  <section class=\"pr2--nodePanelBody evaluation-viewer content-viewer\">\n    <div class=\"evaluation-content\" data-selection-entity=\"evaluation\" data-selection-uuid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":12,"column":92},"end":{"line":12,"column":100}}}) : helper)))
    + "\">\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":13,"column":18}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n    <hr>\n    <div class=\"evaluation-content\">\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"placeholder") || (depth0 != null ? lookupProperty(depth0,"placeholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholder","hash":{},"data":data,"loc":{"start":{"line":17,"column":6},"end":{"line":17,"column":23}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n  </section>\n  <section class=\"pr2--nodePanelBody\">\n    <div class=\"lp--curriculumEditorContainer\"></div>\n  </section>\n  <section class=\"lp--nodePanelUserSection\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/evaluation/user_section"),depth0,{"name":"behaviors/evaluation/user_section","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </section>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/panel_navigation"),depth0,{"name":"behaviors/panel_navigation","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/evaluation/footer"),depth0,{"name":"behaviors/evaluation/footer","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</section>\n";
},"usePartial":true,"useData":true});
templates['behaviors/group/footer'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<!-- Intentionally left empty -->";
},"useData":true});
templates['behaviors/group/static_activity_editor'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"lp--userAvatar\" style=\"background-image:url("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"User") : depth0)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + ")\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lp--activityEditorAttachments "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":18,"column":46},"end":{"line":18,"column":57}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"attachments") : stack1),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":27,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":20,"column":13},"end":{"line":20,"column":20}}}) : helper)))
    + "\" target=\"_blank\">\n      <div class=\"lp--activityEditorAttachmentItem\">\n        <div class=\"lp--activityEditorAttachmentLabel\">\n          "
    + alias4(((helper = (helper = lookupProperty(helpers,"filename") || (depth0 != null ? lookupProperty(depth0,"filename") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filename","hash":{},"data":data,"loc":{"start":{"line":23,"column":10},"end":{"line":23,"column":22}}}) : helper)))
    + "\n        </div>\n      </div>\n      </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--blogPostListItem lp--js-blogPostItem\" data-post-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":1,"column":70},"end":{"line":1,"column":78}}}) : helper)))
    + "\">\n<section class=\"lp--activityEditorSection\">\n    <header class=\"lp--activityEditorSectionHeader\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"TaskSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"TaskReports") : stack1)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"TaskParticipations") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":6,"column":15}}})) != null ? stack1 : "")
    + "      "
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"editor_title_group",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":7,"column":35}}}))
    + "\n      <div class=\"lp--activityEditorHeaderTools\">        \n        <a href=\"#\" class=\"lp--activityEditorHeaderComment lp--js-triggerComment\" data-post-uuid=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "\" title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":122},"end":{"line":9,"column":144}}}))
    + "\" aria-label=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":158},"end":{"line":9,"column":180}}}))
    + "\"></a>\n      </div>\n    </header>\n  <div class=\"lp--activityEditor "
    + alias4(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":12,"column":33},"end":{"line":12,"column":44}}}) : helper)))
    + "\">\n    <div class=\"lp--activityEditorArea lp--js-editor "
    + alias4(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":13,"column":53},"end":{"line":13,"column":64}}}) : helper)))
    + " "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"state") : stack1), depth0))
    + "\" data-selection-entity=\"taskreport\" data-selection-uuid=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "\">"
    + ((stack1 = alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"body") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n    <div class=\"work-in-progress\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"work_in_progress",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":34},"end":{"line":14,"column":63}}}))
    + "</div>\n    <div tabindex=\"0\" class=\"sneak-peak\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"sneak_peak",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":41},"end":{"line":15,"column":64}}}))
    + "</div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"attachments") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":2},"end":{"line":29,"column":9}}})) != null ? stack1 : "")
    + "</section>\n</div>";
},"useData":true});
templates['behaviors/group/viewer_single'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"deadline-wrapper\">\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"TaskSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1), depth0))
    + "\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"panel-content\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"panel_header"),depth0,{"name":"panel_header","hash":{"uuid":(depth0 != null ? lookupProperty(depth0,"uuid") : depth0),"titleKey":"activity_group_name","type":"group"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <header class=\"pr2--panelHeader\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/header"),depth0,{"name":"behaviors/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"TaskSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "  </header>\n  <section class=\"pr2--nodePanelBody task-viewer\">\n    <div class=\"task-content content-viewer\" data-selection-entity=\"task\" data-selection-uuid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":12,"column":95},"end":{"line":12,"column":103}}}) : helper)))
    + "\">\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":13,"column":18}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n  </section>\n  <section class=\"lp--nodePanelUserSection\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/group/user_section"),depth0,{"name":"behaviors/group/user_section","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </section>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/panel_navigation"),depth0,{"name":"behaviors/panel_navigation","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/task/footer"),depth0,{"name":"behaviors/task/footer","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</section>\n";
},"usePartial":true,"useData":true});
templates['behaviors/legend/concept'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <tr tabindex=\"0\" class=\"concept-row\">\n    <td data-concept-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":31}}}) : helper)))
    + "\" class=\"concept-header\">\n      <div class=\"concept-body\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":18}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n    </td>\n    <td>\n      <div class=\"description-body\">\n        "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"Description") : depth0)) != null ? lookupProperty(stack1,"body") : stack1), depth0)) != null ? stack1 : "")
    + "\n      </div>\n    </td>\n  </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"CorrelationList") : depth0)) != null ? lookupProperty(stack1,"Concepts") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":14,"column":9}}})) != null ? stack1 : "");
},"useData":true});
templates['behaviors/legend/done'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<!-- Intentionally left empty -->";
},"useData":true});
templates['behaviors/legend/footer'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<!-- Intentionally left empty -->";
},"useData":true});
templates['behaviors/legend/response'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/legend/done"),depth0,{"name":"behaviors/legend/done","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
templates['behaviors/legend/viewer_single'] = template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"italic\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"correlation_locked_desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":26},"end":{"line":22,"column":60}}}))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"panel-content\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"panel_header"),depth0,{"name":"panel_header","hash":{"titleKey":"activity_legend_name","type":"legend"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <header class=\"pr2--panelHeader\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/header"),depth0,{"name":"behaviors/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </header>\n  <section class=\"pr2--nodePanelBody\">\n    <div class=\"content-viewer\">\n      <div data-selection-entity=\"correlation\" data-selection-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":8,"column":68},"end":{"line":8,"column":76}}}) : helper)))
    + "\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":20}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n      <div class=\"legend-viewer\">\n        <table class=\"concept-list\">\n          <tr>\n            <th class=\"concept-title\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"correlation_term",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":38},"end":{"line":14,"column":65}}}))
    + "</th>\n            <th class=\"concept-title\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"correlation_description",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":38},"end":{"line":15,"column":72}}}))
    + "</th>\n          </tr>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/legend/concept"),depth0,{"name":"behaviors/legend/concept","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </table>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/legend/response"),depth0,{"name":"behaviors/legend/response","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"locked") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":6},"end":{"line":23,"column":13}}})) != null ? stack1 : "")
    + "    <div>\n  </section>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/panel_navigation"),depth0,{"name":"behaviors/panel_navigation","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/correlation/footer"),depth0,{"name":"behaviors/correlation/footer","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</section>\n";
},"usePartial":true,"useData":true});
templates['behaviors/private_blog/footer'] = template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lp--panelBtnGroup\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"editor_locked_desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":35},"end":{"line":4,"column":64}}}))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lp--panelBtnGroup lp--privateBlogFooter\">\n      <div class=\"lp--js-stateSwitchHookStudent\" data-state=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"state") : stack1), depth0))
    + "\"></div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<footer class=\"pr2--panelFooter\">\n  <div class=\"navigation-panel\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"locked") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":9,"column":9}}})) != null ? stack1 : "")
    + "  </div>\n</footer>";
},"useData":true});
templates['behaviors/private_blog/static_activity_editor'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lp--activityEditorAttachments "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":23,"column":46},"end":{"line":23,"column":57}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"attachments") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":6},"end":{"line":32,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":25,"column":15},"end":{"line":25,"column":22}}}) : helper)))
    + "\" target=\"_blank\">\n        <div class=\"lp--activityEditorAttachmentItem\">\n          <div class=\"lp--activityEditorAttachmentLabel\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"filename") || (depth0 != null ? lookupProperty(depth0,"filename") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filename","hash":{},"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":24}}}) : helper)))
    + "\n          </div>\n        </div>\n      </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"lp--blogPostListItem lp--js-blogPostItem\" data-post-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":1,"column":69},"end":{"line":1,"column":77}}}) : helper)))
    + "\">\n  <section class=\"lp--activityEditorSection\">\n    <header class=\"lp--activityEditorSectionHeader lp--activityEditorSectionHeaderLogbook static\">\n      <span class=\"header-user-name\">"
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"BlogSessions"))) && lookupProperty(stack1,"0"))) && lookupProperty(stack1,"BlogReports"))) && lookupProperty(stack1,"0"))) && lookupProperty(stack1,"Participation"))) && lookupProperty(stack1,"User"))) && lookupProperty(stack1,"full_name")), depth0))
    + "</span>\n      <time class=\"header-time\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"updated_at") || (depth0 != null ? lookupProperty(depth0,"updated_at") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"updated_at","hash":{},"data":data,"loc":{"start":{"line":5,"column":32},"end":{"line":5,"column":46}}}) : helper)))
    + "</time>\n      <div class=\"lp--activityEditorHeaderTools\">\n        <div class=\"blogpost-edit-panel "
    + alias4(alias5(((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"allowEditing")), depth0))
    + "\">\n          <a href=\"#\" class=\"lp--activityEditorSectionHeaderEditLink lp--js-editLink\" data-post-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":8,"column":102},"end":{"line":8,"column":110}}}) : helper)))
    + "\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_edit",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":112},"end":{"line":9,"column":24}}}))
    + "</a>\n          <a href=\"#\" class=\"lp--activityEditorSectionHeaderDeleteLink lp--js-deleteLink\"\n            data-post-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":11,"column":36}}}) : helper)))
    + "\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_delete",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":38},"end":{"line":11,"column":59}}}))
    + "</a>\n        </div>\n        <a href=\"#\" class=\"lp--activityEditorHeaderComment lp--js-triggerComment\" data-post-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":13,"column":98},"end":{"line":13,"column":106}}}) : helper)))
    + "\" title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":115},"end":{"line":13,"column":137}}}))
    + "\" aria-label=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":151},"end":{"line":13,"column":173}}}))
    + "\"></a>\n      </div>\n    </header>\n    <div class=\"lp--activityEditor "
    + alias4(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":16,"column":35},"end":{"line":16,"column":46}}}) : helper)))
    + "\">\n      <div class=\"lp--activityEditorArea lp--js-editor "
    + alias4(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":17,"column":55},"end":{"line":17,"column":66}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"state") || (depth0 != null ? lookupProperty(depth0,"state") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"state","hash":{},"data":data,"loc":{"start":{"line":17,"column":67},"end":{"line":17,"column":78}}}) : helper)))
    + "\" data-selection-entity=\"blogpost\"\n        data-selection-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":18,"column":29},"end":{"line":18,"column":37}}}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":18,"column":39},"end":{"line":18,"column":49}}}) : helper))) != null ? stack1 : "")
    + "</div>\n      <div class=\"work-in-progress\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"work_in_progress",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":36},"end":{"line":19,"column":65}}}))
    + "</div>\n      <div tabindex=\"0\" class=\"sneak-peak\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"sneak_peak",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":43},"end":{"line":20,"column":66}}}))
    + "</div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"attachments") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + "  </section>\n</li>";
},"useData":true});
templates['behaviors/private_blog/viewer_single'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"deadline-wrapper\">\n      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"BlogSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1), depth0))
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"panel-content\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"panel_header"),depth0,{"name":"panel_header","hash":{"uuid":(depth0 != null ? lookupProperty(depth0,"uuid") : depth0),"titleKey":"activity_logbook_name","type":"private_blog"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <header class=\"pr2--panelHeader\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/header"),depth0,{"name":"behaviors/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"BlogSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "  </header>\n  <section class=\"pr2--nodePanelBody\">\n    <div class=\"content-viewer\">\n      <div data-selection-entity=\"blog\" data-selection-uuid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":13,"column":61},"end":{"line":13,"column":69}}}) : helper)))
    + "\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":20}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/private_blog/user_section"),depth0,{"name":"behaviors/private_blog/user_section","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </section>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/private_blog/footer"),depth0,{"name":"behaviors/private_blog/footer","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/panel_navigation"),depth0,{"name":"behaviors/panel_navigation","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</section>\n";
},"usePartial":true,"useData":true});
templates['behaviors/public_blog/footer'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<!-- Intentionally left empty -->";
},"useData":true});
templates['behaviors/public_blog/post_overview'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <li class=\"lp--blogPostListItem\" data-userid=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Participation") : depth0)) != null ? lookupProperty(stack1,"User") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" id=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias2,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":2,"column":79},"end":{"line":2,"column":87}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"editable") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "  </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/public_blog/activity_editor"),depth0,{"name":"behaviors/public_blog/activity_editor","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/public_blog/static_activity_editor"),depth0,{"name":"behaviors/public_blog/static_activity_editor","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"BlogSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"BlogReports") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
templates['behaviors/public_blog/static_activity_editor'] = template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"user_attribute_anonymous",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":43}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Participation") : depth0)) != null ? lookupProperty(stack1,"User") : stack1)) != null ? lookupProperty(stack1,"full_name") : stack1), depth0))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"lp--activityEditorHeaderTools\">\n        <a href=\"#\" class=\"lp--activityEditorHeaderComment lp--js-triggerComment\" data-post-uuid=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"BlogPosts") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "\" title=\""
    + alias1((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias3).call(alias2,"app_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":127},"end":{"line":13,"column":149}}}))
    + "\" aria-label=\""
    + alias1((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias3).call(alias2,"app_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":163},"end":{"line":13,"column":185}}}))
    + "\"></a>\n      </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"lp--activityEditor "
    + alias4(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":18,"column":33},"end":{"line":18,"column":44}}}) : helper)))
    + "\">\n    <div class=\"lp--activityEditorArea lp--js-editor "
    + alias4(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":19,"column":53},"end":{"line":19,"column":64}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"state") || (depth0 != null ? lookupProperty(depth0,"state") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"state","hash":{},"data":data,"loc":{"start":{"line":19,"column":65},"end":{"line":19,"column":76}}}) : helper)))
    + "\" data-selection-entity=\"blogpost\" data-selection-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":19,"column":132},"end":{"line":19,"column":140}}}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":19,"column":142},"end":{"line":19,"column":152}}}) : helper))) != null ? stack1 : "")
    + "</div>  \n    <div class=\"work-in-progress\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"work_in_progress",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":34},"end":{"line":20,"column":63}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isViewable") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"attachments") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":2},"end":{"line":37,"column":9}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div tabindex=\"0\" class=\"sneak-peak\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"sneak_peak",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":41},"end":{"line":22,"column":64}}}))
    + "</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lp--activityEditorAttachments "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":26,"column":46},"end":{"line":26,"column":57}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"attachments") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":35,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":28,"column":13},"end":{"line":28,"column":20}}}) : helper)))
    + "\" target=\"_blank\">\n      <div class=\"lp--activityEditorAttachmentItem\">\n        <div class=\"lp--activityEditorAttachmentLabel\">\n          "
    + alias4(((helper = (helper = lookupProperty(helpers,"filename") || (depth0 != null ? lookupProperty(depth0,"filename") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filename","hash":{},"data":data,"loc":{"start":{"line":31,"column":10},"end":{"line":31,"column":22}}}) : helper)))
    + "\n        </div>\n      </div>\n      </a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lp--activityEditor\">\n      <div class=\"lp--activityEditorArea empty\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"static_editor_empty_placeholder",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":48},"end":{"line":40,"column":90}}}))
    + "</div>    \n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"lp--activityEditorSection\">\n  <header class=\"lp--activityEditorSectionHeader\">\n    <div class=\"lp--activityEditorHeaderUser\">\n      <div class=\"lp--userAvatar\" style=\"background-image:url("
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Participation") : depth0)) != null ? lookupProperty(stack1,"User") : stack1)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + ")\"></div>      \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"Participation") : depth0)) != null ? lookupProperty(stack1,"User") : stack1)) != null ? lookupProperty(stack1,"anonymous") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":9,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"BlogPosts") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "")
    + "  </header>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"BlogPosts") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":17,"column":2},"end":{"line":42,"column":11}}})) != null ? stack1 : "")
    + "</section>";
},"useData":true});
templates['behaviors/public_blog/user_actions'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"student-behavior-menu\">\n  <div class=\"comment\"></div>\n</div>";
},"useData":true});
templates['behaviors/public_blog/viewer_list'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"panel-content\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"panel_header"),depth0,{"name":"panel_header","hash":{"uuid":(depth0 != null ? lookupProperty(depth0,"uuid") : depth0),"titleKey":"activity_noticeboard_name","type":"public_blog"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <header class=\"pr2--panelHeader\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/header"),depth0,{"name":"behaviors/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </header>\n  <section class=\"pr2--nodePanelBody\">\n    <div class=\"content-viewer\">\n      <div data-selection-entity=\"blog\" data-selection-uuid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":8,"column":61},"end":{"line":8,"column":69}}}) : helper)))
    + "\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":20}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n    <ul class=\"lp--blogPostList lp--publicBlogPostList\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/public_blog/post_overview"),depth0,{"name":"behaviors/public_blog/post_overview","hash":{"BlogSessions":(depth0 != null ? lookupProperty(depth0,"BlogSessions") : depth0)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </ul>\n  </section>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/panel_navigation"),depth0,{"name":"behaviors/panel_navigation","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/public_blog/footer"),depth0,{"name":"behaviors/public_blog/footer","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</section>\n";
},"usePartial":true,"useData":true});
templates['behaviors/quiz/footer'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<footer class=\"pr2--panelFooter\">\n  <div class=\"navigation-panel\">\n    <div class=\"lp--js-quizFooterHook\"></div>\n  </div>\n</footer>";
},"useData":true});
templates['behaviors/quiz/viewer_single'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"deadline-wrapper\">\n      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"QuizSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1), depth0))
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"panel-content\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"panel_header"),depth0,{"name":"panel_header","hash":{"uuid":(depth0 != null ? lookupProperty(depth0,"uuid") : depth0),"titleKey":"activity_quiz_name","type":"quiz"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <header class=\"pr2--panelHeader\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/header"),depth0,{"name":"behaviors/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"QuizSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "  </header>\n  <section class=\"pr2--nodePanelBody\">\n    <div class=\"content-viewer\">\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":13,"column":16}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n    <div class=\"quiz-viewer\">\n      <div class=\"lp--js-quizHook\"></div>\n    </div>\n  </section>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/quiz/footer"),depth0,{"name":"behaviors/quiz/footer","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/panel_navigation"),depth0,{"name":"behaviors/panel_navigation","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</section>\n";
},"usePartial":true,"useData":true});
templates['behaviors/task/footer'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<!-- Intentionally left empty -->";
},"useData":true});
templates['behaviors/task/static_activity_editor'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lp--activityEditorAttachments "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":16,"column":46},"end":{"line":16,"column":57}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"attachments") : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":25,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":18,"column":13},"end":{"line":18,"column":20}}}) : helper)))
    + "\" target=\"_blank\">\n      <div class=\"lp--activityEditorAttachmentItem\">\n        <div class=\"lp--activityEditorAttachmentLabel\">\n          "
    + alias4(((helper = (helper = lookupProperty(helpers,"filename") || (depth0 != null ? lookupProperty(depth0,"filename") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filename","hash":{},"data":data,"loc":{"start":{"line":21,"column":10},"end":{"line":21,"column":22}}}) : helper)))
    + "\n        </div>\n      </div>\n      </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"lp--blogPostListItem lp--js-blogPostItem\" data-post-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":1,"column":70},"end":{"line":1,"column":78}}}) : helper)))
    + "\">\n<section class=\"lp--activityEditorSection\">\n    <header class=\"lp--activityEditorSectionHeader\">\n      <div class=\"lp--userAvatar\" style=\"background-image:url("
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"user") : depth0)) != null ? lookupProperty(stack1,"image") : stack1), depth0))
    + ")\"></div>\n      "
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"editor_title_task",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":5,"column":34}}}))
    + "\n      <div class=\"lp--activityEditorHeaderTools\">\n        <a href=\"#\" class=\"lp--activityEditorHeaderComment lp--js-triggerComment\" data-post-uuid=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "\" title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":122},"end":{"line":7,"column":144}}}))
    + "\" aria-label=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":158},"end":{"line":7,"column":180}}}))
    + "\"></a>\n      </div>\n    </header>\n  <div class=\"lp--activityEditor "
    + alias4(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":44}}}) : helper)))
    + "\">\n    <div class=\"lp--activityEditorArea lp--js-editor "
    + alias4(((helper = (helper = lookupProperty(helpers,"draft") || (depth0 != null ? lookupProperty(depth0,"draft") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"draft","hash":{},"data":data,"loc":{"start":{"line":11,"column":53},"end":{"line":11,"column":64}}}) : helper)))
    + " "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"state") : stack1), depth0))
    + "\" data-selection-entity=\"taskreport\" data-selection-uuid=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "\">"
    + ((stack1 = alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"body") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n    <div class=\"work-in-progress\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"work_in_progress",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":34},"end":{"line":12,"column":63}}}))
    + "</div>\n    <div tabindex=\"0\" class=\"sneak-peak\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"sneak_peak",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":41},"end":{"line":13,"column":64}}}))
    + "</div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"handin") : depth0)) != null ? lookupProperty(stack1,"attachments") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":27,"column":9}}})) != null ? stack1 : "")
    + "</section>\n</div>";
},"useData":true});
templates['behaviors/task/viewer_single'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"deadline-wrapper\">\n        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"TaskSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1), depth0))
    + "\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"panel-content\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"panel_header"),depth0,{"name":"panel_header","hash":{"uuid":(depth0 != null ? lookupProperty(depth0,"uuid") : depth0),"titleKey":"activity_task_name","type":"task"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <header class=\"pr2--panelHeader\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/header"),depth0,{"name":"behaviors/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"TaskSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "  </header>\n  <section class=\"pr2--nodePanelBody task-viewer\">\n    <div class=\"task-content content-viewer\" data-selection-entity=\"task\" data-selection-uuid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":12,"column":95},"end":{"line":12,"column":103}}}) : helper)))
    + "\">\n      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":13,"column":18}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n  </section>\n  <section class=\"lp--nodePanelUserSection\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/task/user_section"),depth0,{"name":"behaviors/task/user_section","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </section>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/panel_navigation"),depth0,{"name":"behaviors/panel_navigation","hash":{"navigation":(depth0 != null ? lookupProperty(depth0,"navigation") : depth0)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/task/footer"),depth0,{"name":"behaviors/task/footer","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</section>\n";
},"usePartial":true,"useData":true});
templates['behaviors/written_quiz/footer'] = template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lp--panelBtnGroup\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"editor_locked_desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":35},"end":{"line":4,"column":64}}}))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lp--panelBtnGroup lp--privateBlogFooter\">\n      <div class=\"lp--js-stateSwitchHookStudent\" data-state=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"state") || (depth0 != null ? lookupProperty(depth0,"state") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"state","hash":{},"data":data,"loc":{"start":{"line":7,"column":61},"end":{"line":7,"column":70}}}) : helper)))
    + "\"></div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<footer class=\"pr2--panelFooter\">\n  <div class=\"navigation-panel\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"locked") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":9,"column":9}}})) != null ? stack1 : "")
    + "  </div>\n</footer>";
},"useData":true});
templates['behaviors/written_quiz/quiz'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/written_quiz/static_activity_editor_question"),depth0,{"name":"behaviors/written_quiz/static_activity_editor_question","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ol class=\"lp--quizQuestionList\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"QuizQuestions") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":13}}})) != null ? stack1 : "")
    + "</ol>";
},"usePartial":true,"useData":true});
templates['behaviors/written_quiz/static_activity_editor'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"lp--activityEditorAttachments "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"answer") : depth0)) != null ? lookupProperty(stack1,"draft") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"answer") : depth0)) != null ? lookupProperty(stack1,"attachments") : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":26,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":19,"column":13},"end":{"line":19,"column":20}}}) : helper)))
    + "\" target=\"_blank\">\n      <div class=\"lp--activityEditorAttachmentItem\">\n        <div class=\"lp--activityEditorAttachmentLabel\">\n          "
    + alias4(((helper = (helper = lookupProperty(helpers,"filename") || (depth0 != null ? lookupProperty(depth0,"filename") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filename","hash":{},"data":data,"loc":{"start":{"line":22,"column":10},"end":{"line":22,"column":22}}}) : helper)))
    + "\n        </div>\n      </div>\n      </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"lp--quizQuestionItem\" data-post-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":1,"column":49},"end":{"line":1,"column":57}}}) : helper)))
    + "\" data-question-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":77},"end":{"line":1,"column":83}}}) : helper)))
    + "\">\n<section class=\"lp--activityEditorSection\">\n  <header class=\"lp--activityEditorSectionHeader lp--activityEditorSectionHeaderWrittenQuiz\">\n    <div class=\"lp--activityEditorSectionHeaderWrapper\">\n      <div class=\"lp--activityEditorHeaderQuestionBody\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":5,"column":56},"end":{"line":5,"column":66}}}) : helper))) != null ? stack1 : "")
    + "</div>\n    </div>\n    <div class=\"lp--activityEditorHeaderTools\">\n      <a href=\"#\" class=\"lp--activityEditorHeaderComment lp--js-triggerComment\" data-post-uuid=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"answer") : depth0)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "\" title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":120},"end":{"line":8,"column":142}}}))
    + "\" aria-label=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"app_comment",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":156},"end":{"line":8,"column":178}}}))
    + "\"></a>\n    </div>\n  </header>\n  <div class=\"lp--activityEditor "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"answer") : depth0)) != null ? lookupProperty(stack1,"draft") : stack1), depth0))
    + "\">\n    <div class=\"lp--activityEditorArea lp--js-editor "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"answer") : depth0)) != null ? lookupProperty(stack1,"draft") : stack1), depth0))
    + " "
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"answer") : depth0)) != null ? lookupProperty(stack1,"state") : stack1), depth0))
    + "\" data-selection-entity=\"quizanswer\" data-selection-uuid=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"answer") : depth0)) != null ? lookupProperty(stack1,"uuid") : stack1), depth0))
    + "\">"
    + ((stack1 = alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"answer") : depth0)) != null ? lookupProperty(stack1,"body") : stack1), depth0)) != null ? stack1 : "")
    + "</div>    \n    <div class=\"work-in-progress\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"work_in_progress",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":34},"end":{"line":13,"column":63}}}))
    + "</div>\n    <div tabindex=\"0\" class=\"sneak-peak\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"sneak_peak",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":41},"end":{"line":14,"column":64}}}))
    + "</div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"answer") : depth0)) != null ? lookupProperty(stack1,"attachments") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":2},"end":{"line":28,"column":9}}})) != null ? stack1 : "")
    + "</section>\n</li>";
},"useData":true});
templates['behaviors/written_quiz/static_activity_editor_question'] = template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"lp--quizQuestionItem\" data-post-uuid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":1,"column":49},"end":{"line":1,"column":57}}}) : helper)))
    + "\" data-question-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":77},"end":{"line":1,"column":83}}}) : helper)))
    + "\">\n<section class=\"lp--activityEditorSection\">\n  <header class=\"lp--activityEditorSectionHeader lp--activityEditorSectionHeaderWrittenQuiz\">\n    <div class=\"lp--activityEditorSectionHeaderWrapper\">\n      <div class=\"lp--activityEditorHeaderQuestionBody\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":5,"column":56},"end":{"line":5,"column":66}}}) : helper))) != null ? stack1 : "")
    + "</div>\n    </div>  \n  </header>\n  <div class=\"lp--activityEditor\" style=\"min-height: 140px;\">\n    <div class=\"lp--activityEditorArea lp--js-editor\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"written_quiz_student_input_placeholder",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":54},"end":{"line":9,"column":103}}}))
    + "</div>    \n  </div>\n</section>\n</li>";
},"useData":true});
templates['behaviors/written_quiz/viewer_single'] = template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"deadline-wrapper\">\n      "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"QuizSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1), depth0))
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"panel-content\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"panel_header"),depth0,{"name":"panel_header","hash":{"uuid":(depth0 != null ? lookupProperty(depth0,"uuid") : depth0),"titleKey":"activity_written_quiz_name","type":"written_quiz"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <header class=\"pr2--panelHeader\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/header"),depth0,{"name":"behaviors/header","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"QuizSessions") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"deadline_at") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "  </header>\n  <section class=\"pr2--nodePanelBody\">\n    <div class=\"quiz-viewer content-viewer\">\n      <div data-selection-entity=\"quiz\" data-selection-uuid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"uuid") || (depth0 != null ? lookupProperty(depth0,"uuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"uuid","hash":{},"data":data,"loc":{"start":{"line":13,"column":61},"end":{"line":13,"column":69}}}) : helper)))
    + "\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":18}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/written_quiz/quiz"),depth0,{"name":"behaviors/written_quiz/quiz","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </section>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/written_quiz/footer"),depth0,{"name":"behaviors/written_quiz/footer","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"behaviors/panel_navigation"),depth0,{"name":"behaviors/panel_navigation","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</section>\n";
},"usePartial":true,"useData":true});
})();;Handlebars.partials = Handlebars.templates;
