import styled, { css } from 'styled-components';

export const PanelWrapper = styled.section`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const ViewSection = styled.section`
  margin: 0;
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 18px;
  padding: 0 42px;
  margin-top: 5px;

  .ql-editor {
    p {
      margin: 0 0 15px;
    }
  }
`;

export const HeaderUserImage = styled.div`
  height: 30px;
  width: 30px;
  background-size: cover;
  display: flex;
  flex-shrink: 0;
  margin-right: 10px;
  background-position: 50%;
  border-radius: 50%;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const HeaderUserName = styled.div``;

export const HeaderRequestFeedback = styled.div`
  cursor: pointer;
  filter: brightness(1);
  height: 22px;
  transition: filter 0.2s ease-in-out;
  width: 22px;

  &:hover,
  &:focus {
    filter: brightness(1.2);
    transition: filter 0.2s ease-in-out;
  }

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const AutoSaveStatus = styled.div`
  color: ${({ theme }) => theme.colors.black_6};
  margin-right: 10px;
  height: 20px;
`;

export const EditorToolbarButton = styled.div`
  background-color: ${({ theme }) => theme.colors.behaviorGreen};
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 130%;
  border: 1px solid ${({ theme }) => theme.colors.behaviorGreen};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  filter: brightness(1);
  flex-grow: 0;
  flex-shrink: 0;
  height: 22px;
  margin-top: 8px;
  position: absolute;
  right: 15px;
  transition: filter 0.2s ease-in-out;
  width: 22px;
  z-index: 1;

  &:hover,
  &:focus {
    filter: brightness(1.1);
    transition: filter 0.2s ease-in-out;
  }

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}

  ${({ showToolbar }) =>
    showToolbar &&
    css`
      background-size: 10px 10px;
      z-index: 2;
    `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  button {
    background-color: ${({ theme }) => theme.colors.behaviorGreen};
    border: 2px solid ${({ theme }) => theme.colors.behaviorGreen};
    opacity: 1;
    transition: 0.2s opacity ease-in-out;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      background-color: ${({ theme }) => theme.colors.behaviorGreen};
      opacity: 0.8;
      transition: 0.2s opacity ease-in-out;
    }
  }
`;
