import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../app/assets/js/shared/utils.js';
import SelectionManager from '../../../app/assets/js/shared/selections';
import ExpandScreenIcon from '../../../public/assets/images/theme-2/icons/panel-header/fullscreen.svg';
import CollapseScreenIcon from '../../../public/assets/images/theme-2/icons/panel-header/collapse.svg';
import DyslexicFontIcon from '../../../public/assets/images/theme-2/icons/panel-header/dyslexia.svg';
import CloseIcon from '../../../public/assets/images/theme-2/close-black.svg';
import TeacherPanelIcon from '../../../public/assets/images/theme-2/icons/link_arrow_green.svg';

import {
  TopSection,
  TitleWrapper,
  NodeIcon,
  NodeTitle,
  HeaderTools,
  FullScreenHeaderTool,
  VoiceOverHeaderTool,
  DyslexicFontHeaderTool,
  CloseHeaderTool,
  TeacherPanelLink,
  VoiceOverContainer,
  VoiceBar,
} from './styles';

const NodeContentTopSection = ({
  panel,
  nodeType,
  showFullScreenTool = false,
  showVoiceOverTool = false,
  showTeacherPanelLink = false,
  sessionId,
  nodeId,
  viewer,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(document.querySelector('.lp--fullScreenPanel'));

  const nodeIcon = `/assets/images/theme-2/icons/${nodeType}.svg`;
  const nodeTitle = i18n.__(`activity_${nodeType}_name`);

  useEffect(() => {
    document.addEventListener('start.vo', startVoiceOver);
    return () => document.removeEventListener('start.vo', startVoiceOver);
  }, []);

  useEffect(() => {
    const initializeSelections = async () => {
      if (viewer && viewer.supportsCommunication()) {
        window.selectionManager = new SelectionManager();
        window.selectionManager.init({
          viewer,
          commentInstance: viewer.comments,
        });
      }
    };
    initializeSelections();
  }, []);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    document.querySelector('.content-panel').classList.toggle('lp--fullScreenPanel');
  };

  const startVoiceOver = (ev) => {
    setIsPlaying(!isPlaying);
    if (window.responsiveVoice.isPlaying()) {
      window.responsiveVoice.cancel();
    } else {
      const selectedText = ev && ev.detail;
      const text = selectedText || document.querySelector('.voice-content').innerText;
      if (text) {
        window.responsiveVoice.speak(text, Utils.getVOLanguage(), {
          onend: () => {
            setIsPlaying(false);
            window.responsiveVoice.cancel();
          },
        });
      }
    }
  };

  const toggleDyslexicFont = () => {
    document.dispatchEvent(new CustomEvent('toggleDyslexicFont'));
  };

  const closePanel = () => {
    panel.hide();
  };

  const gotoTeacherPanel = () => {
    const teacherPanelPath = window.location.pathname.includes('teacher_panel') ? '' : 'teacher_panel';
    window.location.href = `/learn/${sessionId}/${teacherPanelPath}#${nodeId}/`;
  };

  const topSection = (
    <TopSection>
      <TitleWrapper>
        <NodeIcon src={nodeIcon} className={nodeType} />
        <NodeTitle>{nodeTitle}</NodeTitle>
        {showTeacherPanelLink && <TeacherPanelLink onClick={gotoTeacherPanel} src={TeacherPanelIcon} />}
      </TitleWrapper>
      <HeaderTools>
        {showFullScreenTool && (
          <FullScreenHeaderTool onClick={toggleFullScreen} src={isFullScreen ? CollapseScreenIcon : ExpandScreenIcon} />
        )}
        {showVoiceOverTool && (
          <VoiceOverHeaderTool onClick={() => startVoiceOver()}>
            <VoiceOverContainer isPlaying={isPlaying}>
              <VoiceBar />
              <VoiceBar />
              <VoiceBar />
            </VoiceOverContainer>
          </VoiceOverHeaderTool>
        )}
        <DyslexicFontHeaderTool onClick={toggleDyslexicFont} src={DyslexicFontIcon} />
        <CloseHeaderTool onClick={closePanel} src={CloseIcon} />
      </HeaderTools>
    </TopSection>
  );

  return topSection;
};

export default NodeContentTopSection;

NodeContentTopSection.propTypes = {
  nodeType: PropTypes.string.isRequired,
  showFullScreenTool: PropTypes.bool,
  showVoiceOverTool: PropTypes.bool,
  showTeacherPanelLink: PropTypes.bool,
  sessionId: PropTypes.number,
  nodeId: PropTypes.string,
  panel: PropTypes.object.isRequired,
  viewer: PropTypes.object,
};
