import styled, { css } from 'styled-components';
import { PurpleGuideTab, PurpleGuideWrapper, PurpleGuideContent } from '../PurpleGuide/styles';

export const ClipboardWrapper = styled(PurpleGuideWrapper)`
  background-color: ${({ theme }) => theme.colors.standardBlackBackground};
  top: 160px;
  height: calc(100% - 160px);

  ${PurpleGuideContent} {
    margin-left: -22px;
  }
`;

export const ClipboardTab = styled(PurpleGuideTab)`
  top: 160px;
  height: 135px;
  background-color: ${({ theme }) => theme.colors.standardBlackBackground};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
`;

export const ButtonWrapper = styled.div`
  margin-left: 10px;
  button {
    padding: 9px 18px 9px 14px;

    &:after {
      margin-left: -4px;
      margin-top: -9px;
      background-size: 15px 15px;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemImage = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.iconButtonBorder};
  position: absolute;
  left: 10px;
  flex-shrink: 0;

  ${({ icon }) =>
    icon &&
    css`
      background-image: url(${icon});
      background-repeat: no-repeat;
      background-position: center;
      background-color: ${({ theme }) => theme.colors.transparent};
    `}
`;

export const PasteImage = styled(ItemImage)`
  width: 20px;
  height: 20px;
  left: initial;
  right: 20px;
  border-radius: 0;
  opacity: 0.7;
  transition: opacity 0.2s ease;
`;

export const ClipboardItem = styled.div`
  transition:
    color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  background-color: ${({ theme }) => theme.colors.standardComponentBackground};
  border: 1px solid ${({ theme }) => theme.colors.iconButtonBorder};
  border-radius: ${({ show }) => (show ? '6px 6px 0 0' : '6px')};
  margin: 10px 0;
  min-height: ${({ type }) => (type === 'hub' ? '56px' : '40px')};
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  &:hover,
  &:focus {
    transition:
      color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    background-color: ${({ theme }) => theme.colors.menuButtonBorder};
    color: ${({ theme }) => theme.colors.white};

    ${PasteImage} {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
  }

  ${({ type }) =>
    type === 'availableHub' &&
    css`
      margin: 0 46px 0 0;
      border-radius: 0;
      min-height: 56px;

      :not(&:last-of-type) {
        border-bottom: none;
      }

      &:last-of-type {
        border-radius: 0 0 6px 6px;
      }
    `}
`;

export const AvailableHubsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.standardComponentBackground};
  margin: -10px 46px 20px 0;
`;

export const SelectHub = styled.div`
  display: flex;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.iconButtonBorder};
  font-style: italic;
  opacity: 0.8;
`;

export const ItemName = styled.div`
  font-size: ${({ type }) => (type ? '14px' : '18px')};
  font-weight: ${({ type }) => (type ? 'normal' : 'bold')};
  color: ${({ theme }) => theme.colors.standardTextGrey};
  margin-left: 60px;
  max-width: 175px;
  padding: 10px 0;

  ${({ type }) =>
    type &&
    css`
      &:after {
        content: ${({ show }) =>
          show ? 'url(/assets/images/icons/arrow-up.svg)' : 'url(/assets/images/icons/arrow-down.svg)'};
        position: absolute;
        right: 20px;
        top: ${({ show }) => (show ? '8px' : '10px')};
      }
    `}
`;

export const NodeImage = styled(ItemImage)`
  width: 26px;
  height: 26px;
  left: 18px;
  border: 2px solid ${({ theme }) => theme.colors.transparent};
  background-size: 50%;

  &.chunk,
  &.video,
  &.link {
    border-color: ${({ theme }) => theme.colors.behaviorYellow};
  }

  &.video {
    background-size: 30%;
  }

  &.task {
    background-size: 30%;
    border-color: ${({ theme }) => theme.colors.behaviorBlue};
  }

  &.group {
    border-color: ${({ theme }) => theme.colors.behaviorGreen};
  }

  &.written_quiz {
    background-size: 30%;
    border-color: ${({ theme }) => theme.colors.behaviorBlue};
  }

  &.evaluation {
    background-size: 30%;
    border-color: ${({ theme }) => theme.colors.behaviorPurple};
  }

  &.legend {
    background-size: 30%;
    border-color: ${({ theme }) => theme.colors.behaviorYellow};
  }

  &.quiz {
    border-color: ${({ theme }) => theme.colors.behaviorPink};
  }

  &.correlation {
    border-color: ${({ theme }) => theme.colors.behaviorPink};
  }

  &.private_blog {
    background-size: 45%;
    border-color: ${({ theme }) => theme.colors.behaviorBlue};
  }

  &.public_blog {
    border-color: ${({ theme }) => theme.colors.behaviorGreen};
  }
`;

export const BottomPanel = styled.div`
  display: flex;
  margin-top: auto;
  padding-bottom: 50px;

  > div {
    margin-top: 20px;
  }
`;

export const NoItems = styled.div`
  font-style: italic;
  opacity: 0.7;
  margin-top: 10px;
`;
