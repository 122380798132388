import styled from 'styled-components';

export const PanelWrapper = styled.section`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const PreviewSection = styled.section`
  margin: 0;
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 18px;
  padding: 0 42px;
  margin-top: 5px;
`;

export const ChunkButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  button {
    background-color: ${({ theme }) => theme.colors.behaviorGreen};
    border: 2px solid ${({ theme }) => theme.colors.behaviorGreen};
    opacity: 0.5;
  }
`;

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Question = styled.div`
  display: flex;
  margin-top: 20px;

  > div {
    width: 100%;
  }
`;

export const SubmitPanel = styled.div`
  align-items: flex-end;
  height: 60px;
  justify-content: center;
  padding: 0 30px;
  background: ${({ theme }) => theme.colors.white};
  bottom: 80px;
  box-shadow: -3px -2px 5px ${({ theme }) => theme.colors.black_1};
  position: sticky;
  z-index: 2;
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;
