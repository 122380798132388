import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useApi from '../../hooks/useApi';
import NodeContentTopSection from '../NodeContentTopSection';
import NodeContentHeaderSection from '../NodeContentHeaderSection';
import NodeContentFooterSection from '../NodeContentFooterSection';
import NodeContentSection from '../NodeContentSection';
import ChunkContentPreview from './nodes/ChunkContentPreview';
import TaskContentPreview from './nodes/TaskContentPreview';
import WrittenQuizContentPreview from './nodes/WrittenQuizContentPreview';
import { PanelWrapper } from './styles';

const NodeContentPreview = ({ nodeId, type: baseType, userToken, navigation, panel }) => {
  const { getData, loading } = useApi(userToken);
  const [data, setData] = useState();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [contextId, setContextId] = useState('');

  const previewText = i18n.__(`${baseType}_preview_text`);
  const isArticle = baseType === 'chunk';

  useEffect(() => {
    const getNodeData = async () => {
      if (!loading) {
        const nodeType = isArticle ? 'chunks' : 'behaviors';
        const response = await getData(`/api/v3/editor/${nodeType}/${nodeId}`);
        if (response.success) {
          const body = response.body;
          setType(body.type || '');
          setTitle(body.name || '');
          setDescription((isArticle ? body.data.content : body.data.body) || '');
          setContextId(body.data.uuid);
          setData(body.data);
        }
      }
    };
    getNodeData();
  }, []);

  if (!data) {
    return null;
  }

  const topSection = (
    <NodeContentTopSection nodeType={type} showFullScreenTool={true} showVoiceOverTool={true} panel={panel} />
  );

  const headerSection = <NodeContentHeaderSection title={title} />;

  const contentSection = (
    <NodeContentSection description={description} type={baseType === 'chunk' ? baseType : type} contextId={contextId} />
  );

  const previewSection = () => {
    switch (type) {
      case 'chunk':
      case 'video':
      case 'link':
        return <ChunkContentPreview previewText={previewText} />;
      case 'task':
        return <TaskContentPreview previewText={previewText} />;
      case 'written_quiz':
        return <WrittenQuizContentPreview previewText={previewText} data={data} />;
    }
  };

  const useCommonSubmit = ['written_quiz'].includes(type);

  const footerSection = <NodeContentFooterSection navigation={navigation} useCommonSubmit={useCommonSubmit} />;

  return (
    !loading && (
      <PanelWrapper>
        {topSection}
        {headerSection}
        {contentSection}
        {previewSection()}
        {footerSection}
      </PanelWrapper>
    )
  );
};

export default NodeContentPreview;

NodeContentPreview.propTypes = {
  nodeId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
  navigation: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
};
