import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { defaultTheme, highContrastTheme } from '../../../../src/themes';
import PurpleGuide from '../../../../src/components/PurpleGuide';
import TrialPanel from '../../../../src/components/TrialPanel';
import Clipboard from '../../../../src/components/Clipboard';
import DropDown from '../../../../src/components/DropDown';
import BottomPanel from '../../../../src/layouts/BottomPanel';
import TopPanel from '../../../../src/layouts/TopPanel';
import CustomEmbed from '../../../../src/components/CustomEmbed';
import ReorderHubsPopup from '../../../../src/components/ReorderHubsPopup';
import HubImageManager from '../../../../src/components/HubImageManager';
import NodeContentEditor from '../../../../src/components/NodeContentEditor';
import NodeContentPreview from '../../../../src/components/NodeContentPreview';
import NodeContentViewer from '../../../../src/components/NodeContentViewer';
import NodeContentTeacherPanel from '../../../../src/components/NodeContentTeacherPanel';
import TeamsImportPopup from '../../../../src/pages/MyOrganization/TeamsImportPopup';
import StartSessionPopup from '../../../../src/pages/MySessions/StartSessionPopup';
import SessionSettingsPopup from '../../../../src/pages/MySessions/SessionSettingsPopup';
import InviteParticipantsPopup from '../../../../src/pages/MySessions/InviteParticipantsPopup';
import SessionSharePopup from '../../../../src/pages/MySessions/SessionSharePopup';
import SessionLeavePopup from '../../../../src/pages/MySessions/SessionLeavePopup';
import AnnouncePopup from '../../../../src/components/AnnouncePopup';
import ImageAttributePopup from '../../../../src/components/ImageAttributePopup';
import EmbedSelectionPopup from '../../../../src/components/EmbedSelectionPopup';
import ExpiredAccountPopup from '../../../../src/components/ExpiredAccountPopup';
import LinkEmailPopup from '../../../../src/components/LinkEmailPopup';
import LoopClonePopup from '../../../../src/components/LoopClonePopup';
import LoopRemixPopup from '../../../../src/components/LoopRemixPopup';
import ConfirmPopup from '../../../../src/components/ConfirmPopup';
import CanvasPopup from '../../../../src/components/CanvasPopup';
import ScreenRecorder from '../../../../src/components/ScreenRecorder';
import SimplePopup from '../../../../src/components/SimplePopup';
import PublishLoopPopup from '../../../../src/components/PublishPopup';
import { buildFileServiceImageUrl, isInFrame } from '../../../../src/utils';
import Utils from './utils';
import flash from './flash';

let purpleGuideNodes = [];
let roots = [];
let recording = false;
let toggleRecorder = false;

const theme = Utils.getUserTheme() === 'high_contrast' ? highContrastTheme : defaultTheme;
const getUserId = () => Utils.getUserId();
const getTargetNode = (id, container) =>
  $(`#${id}Wrapper`)[0] || $(`<div id="${id}Wrapper"></div>`).appendTo(container)[0];

const getRoot = (targetNode) => {
  let root = roots.find((root) => root._internalRoot.containerInfo === targetNode);
  if (!root) {
    root = createRoot(targetNode);
    roots.push(root);
  }
  return root;
};

export const PurpleGuideRenderer = {
  renderTrialPanel: (forceOpen, userId, targetNode) => {
    targetNode = targetNode || getTargetNode('trialPanel', 'body');
    if (!targetNode) {
      return;
    }

    const root = getRoot(targetNode);

    const trialDays = parseInt($('body').attr('data-trial-days'));
    userId = userId || getUserId();
    root.render(
      <ThemeProvider theme={theme}>
        <TrialPanel userId={userId} trialDays={trialDays} forceOpen={forceOpen} />
      </ThemeProvider>,
    );
    return targetNode;
  },
  render: (id, userId, targetNode, texts) => {
    targetNode = targetNode || getTargetNode(id, 'body');
    if (!targetNode) {
      return;
    }

    const root = getRoot(targetNode);

    userId = userId || getUserId();
    root.render(
      <ThemeProvider theme={theme}>
        <PurpleGuide id={id} userId={userId} texts={texts} />
      </ThemeProvider>,
    );
    purpleGuideNodes.push(root);
    return targetNode;
  },
  unmountAll: () => {
    if (purpleGuideNodes.length === 0) {
      return;
    }
    purpleGuideNodes.forEach((root) => root.unmount());
    purpleGuideNodes = [];
  },
  unmount: (root) => {
    root.unmount();
  },
  isSwedishSchool: () => {
    const options = (window.editor || window.viewer || window.tp || {}).options;
    return options && options.organization.country_code === 'SE' && options.organization.type === 'school';
  },
};

export const DropDownRenderer = {
  render: (id, container, items, defaultValue, labelledBy) => {
    const targetNode = getTargetNode(id, container);
    if (!targetNode) {
      return;
    }

    const root = getRoot(targetNode);

    root.render(
      <ThemeProvider theme={theme}>
        <DropDown id={id} items={items} isSearchable={true} selectedValue={defaultValue} aria-labelledby={labelledBy} />
      </ThemeProvider>,
    );
    return targetNode;
  },
};

export const BottomPanelRenderer = {
  render: (id, container, props) => {
    const targetNode = getTargetNode(id, container);
    if (!targetNode) {
      return;
    }

    const root = getRoot(targetNode);

    props.isInFrame = isInFrame(props.entryPoint);

    root.render(
      <ThemeProvider theme={theme}>
        <BottomPanel {...props} />
      </ThemeProvider>,
    );
    return targetNode;
  },
};

export const TopPanelRenderer = {
  render: (id, container, props) => {
    const targetNode = getTargetNode(id, container);
    if (!targetNode) {
      return;
    }

    if (props.fileService) {
      props.fileService.buildImageUrl = buildFileServiceImageUrl(props.fileService);
    }

    props.headerType = targetNode.parentNode.dataset.headerType;
    props.isInFrame = isInFrame(props.entryPoint);
    props.flash = flash;

    const root = getRoot(targetNode);

    root.render(
      <ThemeProvider theme={theme}>
        <TopPanel {...props} />
      </ThemeProvider>,
    );
    return targetNode;
  },
};

export const CustomEmbedRenderer = {
  render: (id, container, fileData) => {
    const targetNode = getTargetNode(id, container);
    if (!targetNode) {
      return;
    }
    targetNode.classList.add('custom-embed');
    if (!fileData.templateId) {
      targetNode.classList.add('hidden');
    }
    const isLocked = () => $(container).closest('.lp--activityEditorContainer').hasClass('locked');

    const onSelect = (templateId, title = '', description = '', imageUrl = '') => {
      container.dataset.templateId = templateId;
      container.dataset.title = title;
      container.dataset.description = description;
      container.dataset.imageUrl = imageUrl;
      targetNode.classList.remove('hidden');
      render();
    };

    const onClose = (templateId) => {
      if (!templateId) {
        $(container).remove();
      }
    };

    const render = () => {
      const data = { ...fileData, ...container.dataset };
      const root = getRoot(targetNode);
      const onRender = () => {
        setTimeout(() => {
          if (container.dataset.templateId && !isLocked()) {
            const ev = new CustomEvent('templateSelected', { bubbles: true });
            container.dispatchEvent(ev);
            document.dispatchEvent(ev);
          }
        }, 1000);
      };

      root.render(
        <ThemeProvider theme={theme}>
          <CustomEmbed
            data={data}
            fileService={Utils.getFileService()}
            onSelect={onSelect}
            onClose={onClose}
            isLocked={isLocked}
            onRender={onRender}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const ImageAttributeRenderer = {
  render: (element) => {
    const targetNode = getTargetNode('imageAttributePopup', 'body');
    if (!targetNode) {
      return;
    }

    let popupIsOpen = true;
    const onSave = ({ alt = '', border = '' }) => {
      element.alt = alt;
      element.style.border = border;
      popupIsOpen = false;
      render();
    };

    const onClose = () => {
      popupIsOpen = false;
      render();
    };

    const render = () => {
      const { alt, style } = element;
      const data = { alt, style };
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <ImageAttributePopup
            key={Date.now()}
            data={data}
            popupIsOpen={popupIsOpen}
            onSave={onSave}
            onClose={onClose}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const ReorderHubsPopupRenderer = {
  render: (loopId, userToken) => {
    const targetNode = getTargetNode('reorderHubs', 'body');
    if (!targetNode) {
      return;
    }

    let popupIsOpen = true;
    const onClose = (ev, startNodeId) => {
      popupIsOpen = false;
      render(startNodeId);
    };

    const render = (startNodeId) => {
      const root = getRoot(targetNode);
      const onRender = () => {
        if (startNodeId) {
          $('body').trigger('circle.updated', {
            startNodeId,
          });
        }
      };

      root.render(
        <ThemeProvider theme={theme}>
          <ReorderHubsPopup
            loopId={loopId}
            fileService={Utils.getFileService()}
            userToken={userToken}
            popupIsOpen={popupIsOpen}
            onClose={onClose}
            flash={flash}
            onRender={onRender}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const AnnouncePopupRenderer = {
  render: (userToken, loopId, nodeId = null, subType = null) => {
    const targetNode = getTargetNode('announcePopup', 'body');
    if (!targetNode) {
      return;
    }

    let popupIsOpen = true;
    const onClose = () => {
      popupIsOpen = false;
      render();
    };

    const render = () => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <AnnouncePopup
            userToken={userToken}
            loopId={loopId}
            nodeId={nodeId}
            subType={subType}
            popupIsOpen={popupIsOpen}
            onClose={onClose}
            flash={flash}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const SimplePopupRenderer = {
  render: (headerKey, bodyKey) => {
    const targetNode = getTargetNode('simplePopup', 'body');
    if (!targetNode) {
      return;
    }

    let popupIsOpen = true;
    const onClose = () => {
      popupIsOpen = false;
      render();
    };

    const render = () => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <SimplePopup popupIsOpen={popupIsOpen} onClose={onClose} headerKey={headerKey} bodyKey={bodyKey} />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const HubImagePopupRenderer = {
  render: (userToken, loopId, currentImage, hubId) => {
    const targetNode = getTargetNode('hubImageManager', 'body');
    if (!targetNode) {
      return;
    }

    let popupIsOpen = true;
    const onClose = () => {
      popupIsOpen = false;
      render();
    };

    const onSave = (fileName) => {
      popupIsOpen = false;
      render(fileName);
    };

    const render = (fileName) => {
      const root = getRoot(targetNode);
      const onRender = () => {
        if (fileName) {
          $('body').trigger(`${hubId ? 'hub' : 'loop'}.updated`, {
            fileName,
          });
        }
      };

      root.render(
        <ThemeProvider theme={theme}>
          <HubImageManager
            loopId={loopId}
            currentImage={currentImage}
            userToken={userToken}
            fileService={Utils.getFileService()}
            popupIsOpen={popupIsOpen}
            onClose={onClose}
            onSave={onSave}
            onRender={onRender}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const TeamsImportPopupRenderer = {
  render: (userToken, organization, session) => {
    const targetNode = getTargetNode('TeamsImportPopupRenderer', 'body');
    if (!targetNode) {
      return;
    }

    let popupIsOpen = true;
    const onClose = () => {
      popupIsOpen = false;
      render();
    };

    const render = () => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <TeamsImportPopup
            popupIsOpen={popupIsOpen}
            userToken={userToken}
            organization={organization}
            session={session}
            onClose={onClose}
            allowSimulation={false}
            flash={flash}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const EditNodeRenderer = {
  render: (nodeId, editor, panel) => {
    const targetNode = panel.content[0];
    if (!targetNode) {
      return;
    }

    const render = () => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <NodeContentEditor
            nodeId={nodeId}
            editor={editor}
            panel={panel}
            fileService={Utils.getFileService()}
            flash={flash}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const PreviewNodeRenderer = {
  render: (nodeId, type, navigation, panel) => {
    const targetNode = panel.content[0];
    if (!targetNode) {
      return;
    }

    const render = () => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <NodeContentPreview
            nodeId={nodeId}
            type={type}
            userToken={Utils.getUserToken()}
            navigation={navigation}
            panel={panel}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const ViewNodeRenderer = {
  render: (nodeId, type, options, panel) => {
    const targetNode = panel.content[0];
    if (!targetNode) {
      return;
    }

    const render = () => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <NodeContentViewer
            nodeId={nodeId}
            type={type}
            options={options}
            panel={panel}
            userToken={Utils.getUserToken()}
            fileService={Utils.getFileService()}
            flash={flash}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const TeacherPanelNodeRenderer = {
  render: (nodeId, type, options, panel, student) => {
    const targetNode = panel.content[0];
    if (!targetNode) {
      return;
    }

    const render = () => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <NodeContentTeacherPanel
            nodeId={nodeId}
            type={type}
            options={options}
            panel={panel}
            userToken={Utils.getUserToken()}
            student={student}
            flash={flash}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const StartSessionPopupRenderer = {
  render: (userToken, options) => {
    const targetNode = getTargetNode('startSessionPopup', 'body');
    if (!targetNode) {
      return;
    }
    const { circle_id, circle_name, organization } = options;

    const onClose = (redirectUrl) => {
      render(false);
      if (redirectUrl) {
        setTimeout(() => {
          window.location.href = redirectUrl;
        }, 500);
      }
    };

    const render = (popupIsOpen) => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <StartSessionPopup
            userToken={userToken}
            loopId={circle_id}
            loopName={circle_name}
            organization={organization}
            popupIsOpen={popupIsOpen}
            onClose={onClose}
            flash={flash}
          />
        </ThemeProvider>,
      );
    };
    render(true);

    return targetNode;
  },
};

export const EditSessionPopupRenderer = {
  render: (userToken, session) => {
    const targetNode = getTargetNode('editSessionPopup', 'body');
    if (!targetNode) {
      return;
    }

    const onClose = (redirectUrl) => {
      render(false);
      if (redirectUrl) {
        setTimeout(() => {
          window.location.href = redirectUrl;
        }, 500);
      }
    };

    const render = (popupIsOpen) => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <SessionSettingsPopup
            userToken={userToken}
            loopId={session.circle_id}
            loopName={session.circle_name}
            session={session}
            popupIsOpen={popupIsOpen}
            onClose={onClose}
            flash={flash}
          />
        </ThemeProvider>,
      );
    };
    render(true);

    return targetNode;
  },
};

export const InviteParticipantsPopupRenderer = {
  render: (userToken, loopId, sessionUuid, isTeachers = false, participantIds = []) => {
    const targetNode = getTargetNode('inviteParticipantsPopup', 'body');
    if (!targetNode) {
      return;
    }

    const onClose = () => {
      render(false);
    };

    const render = (popupIsOpen) => {
      const { teachers_can_invite, is_owner } = Utils.getOptions();
      const canInvite = !isTeachers && (teachers_can_invite || is_owner);
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <InviteParticipantsPopup
            userToken={userToken}
            loopId={loopId}
            isTeachers={isTeachers}
            invitedParticipantIds={participantIds}
            isNew={false}
            sessionUuid={sessionUuid}
            popupIsOpen={popupIsOpen}
            onClose={onClose}
            flash={flash}
            showInvitationEmail={canInvite}
          />
        </ThemeProvider>,
      );
    };
    render(true);

    return targetNode;
  },
};

export const ClipboardRenderer = {
  render: () => {
    const targetNode = getTargetNode('clipboard', 'body');
    if (!targetNode) {
      return;
    }

    const root = getRoot(targetNode);

    root.render(
      <ThemeProvider theme={theme}>
        <Clipboard
          userId={getUserId()}
          userToken={Utils.getUserToken()}
          flash={flash}
          fileService={Utils.getFileService()}
          initSnapToGrid={Utils.getGridSetting('snapToGrid', false)}
          initGridSize={Utils.getGridSetting('gridSize', 30)}
        />
      </ThemeProvider>,
    );
    return targetNode;
  },
};

export const SessionCompletedPopupRenderer = {
  render: (progress, certificationEnabled) => {
    const targetNode = getTargetNode('sessionCompletedPopup', 'body');
    if (!targetNode) {
      return;
    }

    const onClose = () => {
      render(false);
    };

    const render = (popupIsOpen) => {
      const isCompleted = progress.completed_progress === 1;
      const contentTextKey = isCompleted ? 'session_complete_text' : 'session_done_text';
      const certificateTextKey = isCompleted ? 'session_certificate_complete_text' : 'session_certificate_done_text';
      const body =
        i18n.__(contentTextKey) +
        (certificationEnabled ? '<br/><br/>' + i18n.__(certificateTextKey) : '') +
        '<br/><br/>';
      const headerKey = 'session_complete_header';

      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <SimplePopup onClose={onClose} popupIsOpen={popupIsOpen} headerKey={headerKey} body={body} />
        </ThemeProvider>,
      );
    };
    render(true);

    return targetNode;
  },
};

export const SessionSharePopupRenderer = {
  render: (codeUrl) => {
    const targetNode = getTargetNode('sessionSharePopup', 'body');
    if (!targetNode) {
      return;
    }

    const onClose = () => {
      render(false);
    };

    const render = (popupIsOpen) => {
      const copyLink = () => Utils.copyToClipboard(codeUrl);
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <SessionSharePopup
            onClose={onClose}
            popupIsOpen={popupIsOpen}
            flash={flash}
            codeUrl={codeUrl}
            copyLink={copyLink}
          />
        </ThemeProvider>,
      );
    };
    render(true);

    return targetNode;
  },
};

export const SessionLeavePopupRenderer = {
  render: (userToken, sessionId) => {
    const targetNode = getTargetNode('sessionLeavePopup', 'body');
    if (!targetNode) {
      return;
    }

    const onClose = () => {
      render(false);
    };

    const render = (popupIsOpen) => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <SessionLeavePopup
            onClose={onClose}
            popupIsOpen={popupIsOpen}
            userToken={userToken}
            flash={flash}
            sessionId={sessionId}
          />
        </ThemeProvider>,
      );
    };
    render(true);

    return targetNode;
  },
};

export const PublishLoopPopupRenderer = {
  render: (userToken, loopId) => {
    const targetNode = getTargetNode('publishLoopPopup', 'body');
    if (!targetNode) {
      return;
    }

    const onClose = () => {
      render(false);
    };

    const render = (popupIsOpen) => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <PublishLoopPopup
            onClose={onClose}
            popupIsOpen={popupIsOpen}
            flash={flash}
            fileService={Utils.getFileService()}
            userToken={userToken}
            entityType="loop"
            entityId={loopId}
          />
        </ThemeProvider>,
      );
    };
    render(true);

    return targetNode;
  },
};

export const EmbedSelectionRenderer = {
  render: (data, updateNode, customEmbed) => {
    const targetNode = getTargetNode('embedSelectionPopup', 'body');
    if (!targetNode) {
      return;
    }

    const onClose = () => {
      render(false);
      updateNode('');
    };

    const onSelect = (html) => {
      render(false);
      updateNode(html);
    };

    const onCustomLink = (data) => {
      render(false);
      customEmbed(data);
    };

    const render = (popupIsOpen) => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <EmbedSelectionPopup
            key={Date.now()}
            onClose={onClose}
            onCustomLink={onCustomLink}
            onSelect={onSelect}
            popupIsOpen={popupIsOpen}
            data={data}
          />
        </ThemeProvider>,
      );
    };
    render(true);

    return targetNode;
  },
};

export const ExpiredAccountPopupRenderer = {
  render: (loopsSupport, type) => {
    const targetNode = getTargetNode('expiredAccountPopup', 'body');
    if (!targetNode) {
      return;
    }

    let popupIsOpen = true;
    const onClose = () => {
      popupIsOpen = false;
      render();
    };

    const render = () => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <ExpiredAccountPopup popupIsOpen={popupIsOpen} onClose={onClose} type={type} loopsSupport={loopsSupport} />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const LinkEmailPopupRenderer = {
  render: (userToken, source = '') => {
    const targetNode = getTargetNode('linkEmailPopup', 'body');
    if (!targetNode) {
      return;
    }

    let popupIsOpen = true;
    const onClose = () => {
      popupIsOpen = false;
      render();
    };

    const render = () => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <LinkEmailPopup
            popupIsOpen={popupIsOpen}
            userToken={userToken}
            flash={flash}
            onClose={onClose}
            source={source}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const LoopClonePopupRenderer = {
  render: (userToken, loopId, defaultName, callback = null, isSession = false) => {
    const targetNode = getTargetNode('loopClonePopup', 'body');
    if (!targetNode) {
      return;
    }

    let popupIsOpen = true;
    const onClose = () => {
      popupIsOpen = false;
      render();
    };

    const onSuccess = (data = {}) => {
      if (callback) {
        callback(data);
      }
      onClose();
    };

    const render = () => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <LoopClonePopup
            popupIsOpen={popupIsOpen}
            flash={flash}
            onClose={onClose}
            onSuccess={onSuccess}
            userToken={userToken}
            loopId={loopId}
            defaultName={defaultName}
            isSession={isSession}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const LoopRemixPopupRenderer = {
  render: (userToken, loopId, defaultName) => {
    const targetNode = getTargetNode('loopRemixPopup', 'body');
    if (!targetNode) {
      return;
    }

    let popupIsOpen = true;
    const onClose = () => {
      popupIsOpen = false;
      render();
    };

    const render = () => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <LoopRemixPopup
            popupIsOpen={popupIsOpen}
            flash={flash}
            onClose={onClose}
            userToken={userToken}
            loopId={loopId}
            defaultName={defaultName}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const ConfirmPopupRenderer = {
  render: (message, title, callback) => {
    const targetNode = getTargetNode('confirmPopup', 'body');
    if (!targetNode) {
      return;
    }

    let popupIsOpen = true;
    const onClose = () => {
      popupIsOpen = false;
      if (callback) {
        callback(false);
      }
      render();
    };

    const onConfirm = () => {
      popupIsOpen = false;
      if (callback) {
        callback(true);
      }
      render();
    };

    const render = () => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <ConfirmPopup
            title={title}
            popupIsOpen={popupIsOpen}
            message={message}
            onConfirm={onConfirm}
            onCancel={onClose}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export const syncConfirmPopup = async (message, title) => {
  return await new Promise((resolve) => {
    ConfirmPopupRenderer.render(message, title, (result) => {
      resolve(result);
    });
  });
};

export const CanvasPopupRenderer = {
  render: (callback) => {
    const targetNode = getTargetNode('canvasPopup', 'body');
    if (!targetNode) {
      return;
    }

    const onClose = () => {
      render(false);
    };

    const onSave = (svg) => {
      callback(svg);
      render(false);
    };

    const render = (popupIsOpen) => {
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <CanvasPopup popupIsOpen={popupIsOpen} onSave={onSave} onClose={onClose} />
        </ThemeProvider>,
      );
    };
    render(true);

    return targetNode;
  },
};

export const ScreenRecorderRenderer = {
  render: (onRecording, onRecordingDone, permissions) => {
    const targetNode = getTargetNode('screenRecorder', 'body');
    if (!targetNode) {
      return;
    }

    let reset;
    const onStartRecording = () => {
      recording = true;
      onRecording(recording);
    };

    const onStopRecording = (blob) => {
      recording = false;
      onRecording(recording);
      onRecordingDone(blob);
    };

    const onReset = () => {
      render(true);
    };

    const render = (reset) => {
      toggleRecorder = reset ? false : !toggleRecorder;
      const root = getRoot(targetNode);
      root.render(
        <ThemeProvider theme={theme}>
          <ScreenRecorder
            toggleRecorder={toggleRecorder}
            recording={recording}
            onStopRecording={onStopRecording}
            onStartRecording={onStartRecording}
            onReset={onReset}
            permissions={permissions}
          />
        </ThemeProvider>,
      );
    };
    render();

    return targetNode;
  },
};

export default PurpleGuideRenderer;
