import styled, { css } from 'styled-components';

export const PanelWrapper = styled.section`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TransitionWrapper = styled.div`
  display: flex;
`;

export const ContentToggle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.2s opacity ease-in-out;

  &:hover,
  &:focus {
    opacity: 1;
    transition: 0.2s opacity ease-in-out;
  }
`;

export const ToggleText = styled.span`
  margin-right: 10px;
`;

export const ToggleIcon = styled.div`
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  ${({ showContent }) =>
    showContent &&
    css`
      transform: rotate(180deg);
    `}

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const ViewSection = styled.section`
  margin: 0;
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 18px;
  padding: 0 42px;
  margin-top: 5px;

  .ql-editor {
    p {
      margin: 0 0 15px;
    }
  }
`;

export const HeaderUserImage = styled.div`
  height: 36px;
  width: 36px;
  background-size: cover;
  display: flex;
  flex-shrink: 0;
  margin-right: 10px;
  background-position: 50%;
  border-radius: 50%;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const HeaderUserName = styled.div``;

export const HeaderRequestFeedback = styled.a`
  color: ${({ theme }) => theme.colors.black_9};
  margin: 15px 0px;
  display: flex;
  opacity: 0.7;
  position: relative;
  padding: 0px 0px 0px 30px;
  transition: 0.2s opacity ease-in-out;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 1;
    transition: 0.2s opacity ease-in-out;
  }

  ${({ src }) =>
    src &&
    css`
      &:before {
        margin: 0px 0px 0px auto;
        background-size: 12px 12px;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 11px;
        background-color: ${({ theme }) => theme.colors.black};
        position: absolute;
        left: 0px;
        content: ' ';
        width: 22px;
        height: 22px;
        background-image: url(${src});
      }
    `}
`;

export const SneakPeak = styled.div`
  background-position: 0;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 30px 10px 10px auto;
  opacity: 0.7;
  padding-left: 25px;

  &:hover,
  &:focus {
    opacity: 1;
    transition: 0.2s;
  }

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const AcceptButton = styled.span`
  width: 26px;
  height: 26px;
  align-self: center;
  cursor: pointer;
  background-repeat: no-repeat;
  flex-shrink: 0;
  opacity: 0.8;
  transition: 0.2s opacity ease-in-out;

  &:hover,
  &:focus {
    opacity: 1;
    transition: 0.2s opacity ease-in-out;
  }

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;
