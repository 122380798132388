import styled from 'styled-components';

export const ContentSection = styled.section`
  margin: 0;
  color: ${({ theme }) => theme.colors.black_9};
  font-size: 18px;
  padding: 0 42px;
  width: 100%;

  img,
  iframe {
    max-width: 100%;
    width: 100%;
  }
`;
