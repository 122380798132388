import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import useApi from '../../hooks/useApi';
import NodeContentTopSection from '../NodeContentTopSection';
import NodeContentHeaderSection from '../NodeContentHeaderSection';
import NodeContentFooterSection from '../NodeContentFooterSection';
import NodeContentSection from '../NodeContentSection';
import TaskContentViewer from './nodes/TaskContentViewer';
import ChunkContentViewer from './nodes/ChunkContentViewer';
import WrittenQuizContentViewer from './nodes/WrittenQuizContentViewer';
import { debounce, getAutoSaveInterval } from '../../utils';

import { PanelWrapper } from './styles';

const NodeContentViewer = ({ nodeId, type: baseType, userToken, fileService, options, panel, flash }) => {
  const { session_id, session_uuid, navigation, integrations, setCheckmark, viewer } = options;
  const { getData, loading } = useApi(userToken);
  const [title, setTitle] = useState('');
  const [user, setUser] = useState();
  const { postData } = useApi(userToken);
  const [updatedAt, setUpdatedAt] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [report, setReport] = useState();
  const [data, setData] = useState();
  const [isTeacher, setIsTeacher] = useState(false);
  const [autoSaveStatus, setAutoSaveStatus] = useState({});
  const [contextId, setContextId] = useState('');

  const isArticle = baseType === 'chunk';
  const nodeType = isArticle ? 'chunks' : 'behaviors';

  useEffect(() => {
    const getNodeData = async () => {
      const response = await getData(`/api/v3/viewer/${nodeType}/${nodeId}/session/${session_uuid}`);
      if (response.success) {
        const body = response.body;
        setIsUpdating(true);
        setIsTeacher(body.role === 'teacher');
        setTitle(body.name || '');
        setType(body.type || '');
        setUser(body.user);
        setUpdatedAt(body.updated_at);
        setDescription((isArticle ? body.data.content : body.data.body) || '');
        setContextId(body.data.uuid);
        setReport({ ...body.report, editorId: 'contentEditor' });
        setData(body.data);
        setIsUpdating(false);
      }
    };
    getNodeData();
  }, []);

  if (!window.autoSaveTimer) {
    window.autoSaveTimer = {};
  }

  const debouncedSave = useCallback(
    debounce(async (newReport, timestamp) => {
      await save(newReport, timestamp);
      window.autoSaveTimer[newReport.editorId] = setTimeout(() => {
        setAutoSaveStatus({ ...autoSaveStatus, [newReport.editorId]: '' });
      }, 5000);
    }, getAutoSaveInterval()),
    [],
  );

  useEffect(() => {
    if (updatedAt && !isTeacher && !isUpdating) {
      setAutoSaveStatus({ ...autoSaveStatus, [report.editorId]: i18n.__('behavior_saving') });
      clearTimeout(window.autoSaveTimer[report.editorId]);
      debouncedSave(report, updatedAt);
    }
  }, [report, debouncedSave]);

  const toggleComments = () => {
    const detail = { entityId: nodeId, entityType: 'node' };
    document.dispatchEvent(new CustomEvent('toggleComments', { detail }));
  };

  const save = async (newReport, timestamp) => {
    const { uuid, body, state, draft: isDraft, answers, editorId } = newReport;
    const payload = { reportId: uuid, body, state, isDraft, answers, updated_at: timestamp };
    const response = await postData(`/api/v3/viewer/${nodeType}/${nodeId}/session/${session_uuid}/report`, payload);
    if (response.success) {
      setIsUpdating(true);
      setAutoSaveStatus({ ...autoSaveStatus, [editorId]: i18n.__('behavior_saved') });
      const body = response.body;
      setUpdatedAt(body.updated_at);
      setCheckmark(state === 'done');
      document.dispatchEvent(new CustomEvent('progressUpdated', { detail: body }));
      setIsUpdating(false);
    } else {
      setAutoSaveStatus({ ...autoSaveStatus, [editorId]: i18n.__('behavior_save_failed') });
      console.error(response);
      if (response.message === 'auth_failed') {
        flash(i18n.__('app_error_unauthorized'), { ttl: 5000 });
      } else if (response.message === 'edit_conflict') {
        flash(i18n.__(`error_edit_conflict_${type}`), { ttl: 5000 });
      } else if (response.message === 'payload_too_large') {
        flash(i18n.__('error_payload_too_large'), { ttl: 20000 });
      } else {
        flash(i18n.__('error_behavior_save_failed'));
      }
    }
  };

  if (!data) {
    return null;
  }

  const topSection = (
    <NodeContentTopSection
      nodeType={type}
      showFullScreenTool={true}
      showVoiceOverTool={true}
      showTeacherPanelLink={isTeacher}
      nodeId={nodeId}
      sessionId={session_id}
      panel={panel}
      viewer={viewer}
    />
  );

  const headerSection = <NodeContentHeaderSection title={title} toggleComments={toggleComments} />;

  const contentSection = (
    <NodeContentSection description={description} type={baseType === 'chunk' ? baseType : type} contextId={contextId} />
  );

  const viewSection = () => {
    switch (type) {
      case 'chunk':
      case 'video':
      case 'link':
        return <ChunkContentViewer report={report} setReport={setReport} isTeacher={isTeacher} />;
      case 'task':
        return (
          <TaskContentViewer
            type={type}
            report={report}
            setReport={setReport}
            user={user}
            fileService={fileService}
            integrations={integrations}
            panel={panel}
            autoSaveStatus={autoSaveStatus}
            isTeacher={isTeacher}
          />
        );
      case 'written_quiz':
        return (
          <WrittenQuizContentViewer
            type={type}
            report={report}
            setReport={setReport}
            data={data}
            fileService={fileService}
            integrations={integrations}
            panel={panel}
            autoSaveStatus={autoSaveStatus}
            isTeacher={isTeacher}
          />
        );
    }
  };

  const useCommonSubmit = ['written_quiz'].includes(type);

  const footerSection = <NodeContentFooterSection navigation={navigation} useCommonSubmit={useCommonSubmit} />;

  return (
    !loading && (
      <PanelWrapper>
        {topSection}
        {headerSection}
        {contentSection}
        {viewSection()}
        {footerSection}
      </PanelWrapper>
    )
  );
};

export default NodeContentViewer;

NodeContentViewer.propTypes = {
  nodeId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
  fileService: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
  flash: PropTypes.func.isRequired,
};
