import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useApi from '../../hooks/useApi';
import NodeContentTopSection from '../NodeContentTopSection';
import NodeContentHeaderSection from '../NodeContentHeaderSection';
import NodeContentSection from '../NodeContentSection';
import ChunkContentTeacherPanel from './nodes/ChunkContentTeacherPanel';
import TaskContentTeacherPanel from './nodes/TaskContentTeacherPanel';
import WrittenQuizContentTeacherPanel from './nodes/WrittenQuizContentTeacherPanel';
import ArrowIcon from '../../icons/arrow-down-white.svg';
import { PanelWrapper, ContentWrapper, ContentToggle, ToggleText, ToggleIcon, TransitionWrapper } from './styles';

const NodeContentTeacherPanel = ({ nodeId, type: baseType, userToken, panel, options, student, flash }) => {
  const { uuid: studentId } = student;
  const { session_id, session_uuid } = options;
  const { getData, putData, loading } = useApi(userToken);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [report, setReport] = useState();
  const [data, setData] = useState();
  const [showContent, setShowContent] = useState(false);
  const [contextId, setContextId] = useState('');

  const isArticle = baseType === 'chunk';

  useEffect(() => {
    const getNodeData = async () => {
      const nodeType = isArticle ? 'chunks' : 'behaviors';
      const response = await getData(`/api/v3/viewer/${nodeType}/${nodeId}/session/${session_uuid}/${studentId}`);
      if (response.success) {
        const body = response.body;
        setType(body.type || '');
        setTitle(body.name || '');
        setDescription((isArticle ? body.data.content : body.data.body) || '');
        setContextId(body.data.uuid);
        setReport(body.report);
        setData(body.data);
      }
    };
    getNodeData();
  }, []);

  if (!data) {
    return null;
  }

  const { uuid: reportId } = report;

  const toggleComments = () => {
    document.dispatchEvent(new CustomEvent('toggleComments', { detail: { entityId: nodeId, entityType: 'node' } }));
  };

  const accept = async () => {
    const payload = { report_uuid: reportId, state: 'accepted' };
    const response = await putData(`/learn/behaviors/${nodeId}/session/${session_id}/state`, payload);
    if (response.success) {
      panel.hide();
      window.dispatchEvent(new CustomEvent('progress.updated', { detail: response.body }));
    } else {
      console.error(response);
      if (response.message === 'auth_failed') {
        flash(i18n.__('app_error_unauthorized'), { ttl: 5000 });
      } else if (response.message === 'edit_conflict') {
        flash(i18n.__('error_edit_conflict_behavior'), { ttl: 5000 });
      } else if (response.message === 'payload_too_large') {
        flash(i18n.__('error_payload_too_large'), { ttl: 20000 });
      } else {
        flash(i18n.__('error_behavior_save_failed'));
      }
    }
  };

  const topSection = (
    <NodeContentTopSection
      nodeType={type}
      showVoiceOverTool={true}
      showTeacherPanelLink={true}
      nodeId={nodeId}
      sessionId={session_id}
      panel={panel}
    />
  );

  const headerSection = <NodeContentHeaderSection title={title} toggleComments={toggleComments} />;

  const contentSection = (
    <ContentWrapper>
      <ContentToggle onClick={() => setShowContent(!showContent)}>
        <ToggleText>{showContent ? i18n.__('hide_assignment') : i18n.__('show_assignment')}</ToggleText>
        <ToggleIcon showContent={showContent} src={ArrowIcon} />
      </ContentToggle>
      {showContent && (
        <TransitionWrapper>
          <NodeContentSection
            description={description}
            type={baseType === 'chunk' ? baseType : type}
            contextId={contextId}
          />
        </TransitionWrapper>
      )}
    </ContentWrapper>
  );

  const viewSection = () => {
    switch (type) {
      case 'chunk':
      case 'video':
      case 'link':
        return <ChunkContentTeacherPanel />;
      case 'task':
        return <TaskContentTeacherPanel student={student} report={report} accept={accept} />;
      case 'written_quiz':
        return <WrittenQuizContentTeacherPanel data={data} report={report} accept={accept} />;
    }
  };

  return (
    !loading && (
      <PanelWrapper>
        {topSection}
        {headerSection}
        {contentSection}
        {viewSection()}
      </PanelWrapper>
    )
  );
};

export default NodeContentTeacherPanel;

NodeContentTeacherPanel.propTypes = {
  nodeId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  panel: PropTypes.object.isRequired,
  student: PropTypes.object.isRequired,
  flash: PropTypes.func.isRequired,
};
