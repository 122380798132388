import styled, { css } from 'styled-components';

export const FooterSection = styled.section`
  align-items: center;
  background: {({ theme }) => theme.colors.white};
  border-top: 1px solid {({ theme }) => theme.colors.black_1}
  bottom: 0;
  display: flex;
  font-size: 15px;
  height: 80px;
  padding: 0 20px;
  position: sticky;
  z-index: 2;

  margin-top: ${({ useCommonSubmit }) => (useCommonSubmit ? 0 : 'auto')};
`;

export const RightArrowIcon = styled.span`
  width: 38px;
  height: 38px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const UpArrowIcon = styled(RightArrowIcon)`
  transform: rotate(-90deg);
  margin-right: 10px;
  margin-left: 0;
`;

export const NavigationUp = styled.div``;

export const NavigationLink = styled.a``;

export const NavigationWrapperUp = styled.div`
  align-items: center;
  background-size: 13px auto;
  display: flex;
  padding-left: 0;
  position: relative;
  height: 26px;
  min-height: 20px;
  color: ${({ theme }) => theme.colors.black_8};
  opacity: 0.8;
  transition: opacity 0.15s ease-in-out;
  margin-top: 0;

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
  }
`;

export const NavigationWrapperRight = styled.div`
  align-items: center;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 15px auto;
  display: flex;
  font-size: 15px;
  height: 26px;
  min-height: 20px;
  padding-left: 37px;
  position: relative;
  color: ${({ theme }) => theme.colors.black_8};
  opacity: 0.8;
  transition: opacity 0.15s ease-in-out;

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
  }
`;

export const HubIcon = styled.span`
  background-color: ${({ theme }) => theme.colors.black_6};
  background-size: 42px auto;
  border-radius: 100px;
  display: inline-block;
  flex-shrink: 0;
  height: 42px;
  left: 0;
  margin-right: 9px;
  position: relative;
  top: 0;
  width: 42px;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}
`;

export const HubName = styled.span`
  font-size: 20px;
`;

export const NavigationNext = styled.div`
  margin-left: auto;
  text-align: right;
`;

export const NodeName = styled.span``;

export const NodeIcon = styled.span`
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 26px;
  left: 0;
  position: absolute;
  width: 26px;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
    `}

  &.chunk, &.video, &.link {
    background-size: 13px auto;
    border: 2px solid ${({ theme }) => theme.colors.behaviorYellow};
  }

  &.video {
    background-position: 8px 5px;
    background-size: 8px auto;
  }

  &.link {
    background-size: 12px auto;
  }

  &.task {
    background-size: 8px auto;
    border: 2px solid ${({ theme }) => theme.colors.behaviorBlue};
  }

  &.group {
    border: 2px solid ${({ theme }) => theme.colors.behaviorGreen};
    background-size: 14px auto;
    background-position: 4px 4px;
  }

  &.legend {
    border: 2px solid ${({ theme }) => theme.colors.behaviorYellow};
    background-size: 8px auto;
  }

  &.correlation {
    background-size: 13px auto;
    border: 2px solid ${({ theme }) => theme.colors.behaviorPink};
  }

  &.evaluation {
    background-size: 9px auto;
    border: 2px solid ${({ theme }) => theme.colors.behaviorPurple};
  }

  &.written_quiz {
    border: 2px solid ${({ theme }) => theme.colors.behaviorBlue};
    background-size: 8px auto;
  }

  &.quiz {
    border: 2px solid ${({ theme }) => theme.colors.behaviorPink};
    background-size: 18px;
  }

  &.public_blog {
    border: 2px solid ${({ theme }) => theme.colors.behaviorGreen};
    background-size: 10px auto;
  }

  &.private_blog {
    border: 2px solid ${({ theme }) => theme.colors.behaviorBlue};
    background-size: 11px auto;
    background-position: 6px 5px;
  }
`;
