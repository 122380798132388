import Remote from './remote';
import axios from 'axios';
import { buildFileServiceImageUrl } from '../../../../src/utils';

// Simple collection of helper functions
export default class Utils {
  static renameKey(object, oldKey, newKey) {
    object[newKey] = object[oldKey];
    delete object[oldKey];
  }

  static async getRecordingPermissions() {
    const permissions = {};
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      permissions.audio = true;
    } catch (err) {
      permissions.audio = false;
    }
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      permissions.video = true;
    } catch (err) {
      permissions.video = false;
    }
    return permissions;
  }

  static GetFileExtension(fileName) {
    const extensionRegexp = /(?:\.([^.]+))?$/;
    return extensionRegexp.exec(fileName)[1];
  }

  static getOptions() {
    return (window.editor || window.viewer || window.tp)?.options || {};
  }

  static getOfficePreviewUrl() {
    return Utils.getOptions().office.previewUrl;
  }

  static getFileService() {
    const fileService = Utils.getOptions().fileservice;
    fileService.buildImageUrl = buildFileServiceImageUrl(fileService);
    return fileService;
  }

  static getFileServiceUrl() {
    return Utils.getFileService().url;
  }

  static getUserToken() {
    return Utils.getOptions().userToken;
  }

  static getUserId() {
    return Utils.getCurrentUserData().uuid;
  }

  static getUserTheme() {
    return Utils.getCurrentUserData().theme;
  }

  static tryLoadImage(url) {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => resolve(true);
      image.onerror = () => resolve(false);
      image.src = url;
    });
  }

  static getFormData(fileService) {
    const { signature, timestamp } = fileService;
    const formData = new FormData();
    formData.append('client', 'pr2');
    formData.append('protected', 0);
    formData.append('public', 1);
    formData.append('signature', signature);
    formData.append('timestamp', timestamp);
    formData.append('circle', Utils.getOptions().circle_id);
    formData.append('user_uuid', Utils.getCurrentUserData().uuid);
    return formData;
  }

  static getGridSetting(key, defaultValue) {
    const userGridSettingKey = `${Utils.getUserId()}_gridSetting_${key}`;
    const value = window.localStorage.getItem(userGridSettingKey) || defaultValue;
    return value && JSON.parse(value);
  }

  static async downloadFile(url, options, onDownloadProgress, mimeType) {
    const config = { ...options, responseType: 'blob', onDownloadProgress };

    try {
      const response = await axios.get(url, config);
      if (response) {
        return new Blob([response.data], { type: mimeType });
      } else {
        console.error(response);
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static async uploadFileToArchivist({ file, onUploadProgress = () => {}, fileName, fileService }) {
    const file_service = fileService || Utils.getFileService();
    const formData = Utils.getFormData(file_service);
    formData.append('file', file, fileName);

    try {
      const response = await axios.post(file_service.url + 'upload', formData, { onUploadProgress });
      if (response) {
        return response.data;
      } else {
        console.error(response);
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  static isOfFileType(fileName, fileTypes) {
    return fileTypes.some((type) => fileName.toLowerCase().endsWith(`.${type}`));
  }

  static fileIsPdf(fileName) {
    return Utils.isOfFileType(fileName, ['pdf']);
  }

  static fileIsOffice(fileName) {
    return Utils.isOfFileType(fileName, ['pptx', 'xlsx', 'docx']);
  }

  static fileIsEmbedable(fileName) {
    return Utils.isOfFileType(fileName, ['txt', 'pdf']) || Utils.fileIsOffice(fileName);
  }

  static noop() {}

  static is_empty(chunks) {
    // If nodes contain an iframe, we consider it non empty
    var elements = $(chunks);

    if (elements.find('iframe, img').length > 0) {
      return false;
    }

    var text_nodes = [];
    $.each(elements, (i) => {
      var n,
        walk = document.createTreeWalker(elements[i], NodeFilter.SHOW_TEXT, null, false);
      while ((n = walk.nextNode())) text_nodes.push(n);
    });

    // Check all p nodes for textcontent
    var empty = true;
    $.each(text_nodes, function (item) {
      if (text_nodes[item].textContent.trim()) {
        empty = false;
      }
    });

    if (!empty) {
      return false;
    }

    return true;
  }

  static observeVideoProcessing(fileservice_url, uuid) {
    return (function () {
      fileservice_url = fileservice_url.slice(0, -1);
      let remote = new Remote(fileservice_url);
      let timer = setInterval(function () {
        remote.get(`api/${uuid}`).then((data) => {
          if (data) {
            clearInterval(timer);
            data.media.uuid = uuid;
            data.media.poster = fileservice_url + data.media.poster.substring(data.media.poster.lastIndexOf('/'));
            $(document).trigger('video.encoded', data.media);
          }
        });
      }, 1500);
    })();
  }

  static promiseWithFuse(cb, prom, fuse = 1000) {
    var timer = setTimeout(function () {
      cb();
    }, fuse);
    return prom.then(function (data) {
      clearTimeout(timer);
      return Promise.resolve(data);
    });
  }

  static getCurrentUserData() {
    return {
      uuid: document.body.getAttribute('data-user-uuid'),
      email: document.body.getAttribute('data-user-email'),
      theme: document.body.getAttribute('data-user-theme'),
    };
  }

  static isEnterprize() {
    if (document.body.getAttribute('data-user-enterprize') == 'true') {
      return true;
    } else {
      return false;
    }
  }

  static getVOLanguage() {
    switch (document.body.getAttribute('data-user-base-locale')) {
      case 'sv-SE':
        return 'Swedish Female';
      case 'da-DK':
        return 'Danish Female';
      case 'nb-NO':
        return 'Norwegian Female';
      case 'es-ES':
        return 'Spanish Female';
      case 'de-DE':
        return 'Deutsch Female';
      case 'fr-FR':
        return 'French Female';
      case 'ar-SA':
        return 'Arabic Female';
      default:
        return 'UK English Female';
    }
  }

  static generateElementID() {
    if (crypto && crypto.randomUUID) {
      return crypto.randomUUID();
    }
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  static debounce(fn, delay) {
    var timer = null;
    return function () {
      var context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    };
  }

  static getDisplayableDate() {
    return new Date().toLocaleDateString((locale || '').split('_')[0]);
  }

  static relativeTime(time) {
    var seconds = Math.floor((new Date() - parseInt(time)) / 1000),
      separator = ' ',
      words = separator,
      interval = 0,
      intervals = {
        year: seconds / 31536000,
        month: seconds / 2592000,
        day: seconds / 86400,
        hour: seconds / 3600,
        minute: seconds / 60,
      };

    var distance = i18n.__('time_seconds');

    for (var key in intervals) {
      interval = Math.floor(intervals[key]);

      if (interval > 1) {
        distance = i18n.__('time_' + key + 's');
        break;
      } else if (interval === 1) {
        distance = i18n.__('time_' + key);
        break;
      }
    }

    distance = distance.replace(/%d/i, interval);
    words += distance + separator + i18n.__('time_sufix');
    return words.trim();
  }

  static isInertActivityReportType(type) {
    return ['chunk', 'legend'].indexOf(type) > -1;
  }

  static getAvailableBehaviorStates(current, isTeacher) {
    switch (current) {
      case 'blank':
        if (isTeacher) {
          return [{ event: 'active' }, { event: 'accept', label: 'done' }];
        } else {
          return [{ event: 'active' }, { event: 'feedback' }, { event: 'done' }];
        }
      case 'active':
        if (isTeacher) {
          return [{ event: 'accept', label: 'done' }];
        } else {
          return [{ event: 'feedback' }, { event: 'done' }];
        }
      case 'feedback':
        if (isTeacher) {
          return [
            { event: 'active' },
            { event: 'return_feedback', label: 'feedback' },
            { event: 'accept', label: 'done' },
          ];
        } else {
          return [{ event: 'active' }, { event: 'done' }];
        }
      case 'done':
        if (isTeacher) {
          return [{ event: 'active' }, { event: 'accept', label: 'done' }];
        } else {
          return [{ event: 'active' }];
        }
      case 'accepted':
      case 'accept':
        return [{ event: 'active' }];
      default:
        return [{ event: 'active' }, { event: 'done' }];
    }
  }

  static copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  static getAppURL() {
    return document.body.getAttribute('data-app-url');
  }

  static hashParams(str) {
    return Object.fromEntries(
      str.split('&').map((item) => {
        let [k, v] = item.split('=');
        return [k, v ? decodeURIComponent(v) : ''];
      }),
    );
  }

  static queryParamsFromObject(obj) {
    return Object.entries(obj)
      .map(([k, v]) => {
        return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
      })
      .join('&');
  }

  static moveCursorToEnd(inputSelector) {
    const value = $(inputSelector).val();
    $(inputSelector).focus().val('').val(value);
  }
}

Utils.extend =
  Object.assign != null
    ? Object.assign
    : function (tgt) {
        var args = arguments;

        for (var i = 1; i < args.length; i++) {
          var obj = args[i];

          for (var k in obj) {
            tgt[k] = obj[k];
          }
        }

        return tgt;
      };

Utils.tapInteractionEvent = 'click';
Utils.interactionEvent = 'click keypress';

!(function (e, t, options = {}) {
  'function' == typeof define && define.amd
    ? define([], t)
    : 'object' == typeof module && module.exports
      ? (module.exports = t())
      : (e.Autogrow = t());
})(window, function () {
  return function (e, t, options = {}) {
    var o = e;
    void 0 === t && (t = 999),
      (o.getOffset = function (e) {
        for (
          var t = window.getComputedStyle(e, null), o = ['paddingTop', 'paddingBottom'], n = 0, i = 0;
          i < o.length;
          i++
        )
          n += parseInt(t[o[i]]);
        return n;
      }),
      (o.autogrowFn = function () {
        var t = 0,
          i = !1;
        return (
          e.scrollHeight - n > o.maxAllowedHeight
            ? ((e.style.overflowY = 'scroll'), (t = o.maxAllowedHeight))
            : ((e.style.overflowY = 'hidden'),
              (e.style.height = typeof options.heightResetValue == 'undefined' ? 'auto' : options.heightResetValue),
              (t = e.scrollHeight - n),
              (i = !0)),
          (e.style.height = t + 'px'),
          i
        );
      });
    var n = o.getOffset(e);
    var lineHeight = typeof options.lineHeight == 'undefined' ? o.lineHeight : options.lineHeight;

    o.rows = e.rows || 1;
    o.lineHeight = e.scrollHeight / o.rows - n / o.rows;
    o.maxAllowedHeight = o.lineHeight * t - n;
    e.addEventListener('input', o.autogrowFn);
    o.autogrowFn();
  };
});

/* global define, Promise */
(function (root, factory) {
  'use strict';
  if (typeof module === 'object' && module.exports && typeof require === 'function') {
    // CommonJS
    module.exports = factory();
  } else if (typeof define === 'function' && typeof define.amd === 'object') {
    // AMD. Register as an anonymous module.
    define(factory);
  } else {
    // Browser globals
    root.Queue = factory();
  }
})(window, function () {
  'use strict';

  /**
   * @return {Object}
   */
  var LocalPromise =
    typeof Promise !== 'undefined'
      ? Promise
      : function () {
          return {
            then: function () {
              throw new Error('Queue.configure() before use Queue');
            },
          };
        };

  var noop = function () {};

  /**
   * @param {*} value
   * @returns {LocalPromise}
   */
  var resolveWith = function (value) {
    if (value && typeof value.then === 'function') {
      return value;
    }

    return new LocalPromise(function (resolve) {
      resolve(value);
    });
  };

  /**
   * It limits concurrently executed promises
   *
   * @param {Number} [maxPendingPromises=Infinity] max number of concurrently executed promises
   * @param {Number} [maxQueuedPromises=Infinity]  max number of queued promises
   * @constructor
   *
   * @example
   *
   * var queue = new Queue(1);
   *
   * queue.add(function () {
   *     // resolve of this promise will resume next request
   *     return downloadTarballFromGithub(url, file);
   * })
   * .then(function (file) {
   *     doStuffWith(file);
   * });
   *
   * queue.add(function () {
   *     return downloadTarballFromGithub(url, file);
   * })
   * // This request will be paused
   * .then(function (file) {
   *     doStuffWith(file);
   * });
   */
  function Queue(maxPendingPromises, maxQueuedPromises, options) {
    this.options = options = options || {};
    this.pendingPromises = 0;
    this.maxPendingPromises = typeof maxPendingPromises !== 'undefined' ? maxPendingPromises : Infinity;
    this.maxQueuedPromises = typeof maxQueuedPromises !== 'undefined' ? maxQueuedPromises : Infinity;
    this.queue = [];
  }

  /**
   * Defines promise promiseFactory
   * @param {Function} GlobalPromise
   */
  Queue.configure = function (GlobalPromise) {
    LocalPromise = GlobalPromise;
  };

  /**
   * @param {Function} promiseGenerator
   * @return {LocalPromise}
   */
  Queue.prototype.add = function (promiseGenerator) {
    var self = this;
    return new LocalPromise(function (resolve, reject, notify) {
      // Do not queue to much promises
      if (self.queue.length >= self.maxQueuedPromises) {
        reject(new Error('Queue limit reached'));
        return;
      }

      // Add to queue
      self.queue.push({
        promiseGenerator: promiseGenerator,
        resolve: resolve,
        reject: reject,
        notify: notify || noop,
      });

      self._dequeue();
    });
  };

  /**
   * Number of simultaneously running promises (which are resolving)
   *
   * @return {number}
   */
  Queue.prototype.getPendingLength = function () {
    return this.pendingPromises;
  };

  /**
   * Number of queued promises (which are waiting)
   *
   * @return {number}
   */
  Queue.prototype.getQueueLength = function () {
    return this.queue.length;
  };

  /**
   * @returns {boolean} true if first item removed from queue
   * @private
   */
  Queue.prototype._dequeue = function () {
    var self = this;
    if (this.pendingPromises >= this.maxPendingPromises) {
      return false;
    }

    // Remove from queue
    var item = this.queue.shift();
    if (!item) {
      if (this.options.onEmpty) {
        this.options.onEmpty();
      }
      return false;
    }

    try {
      this.pendingPromises++;

      resolveWith(item.promiseGenerator())
        // Forward all stuff
        .then(
          function (value) {
            // It is not pending now
            self.pendingPromises--;
            // It should pass values
            item.resolve(value);
            self._dequeue();
          },
          function (err) {
            // It is not pending now
            self.pendingPromises--;
            // It should not mask errors
            item.reject(err);
            self._dequeue();
          },
          function (message) {
            // It should pass notifications
            item.notify(message);
          },
        );
    } catch (err) {
      self.pendingPromises--;
      item.reject(err);
      self._dequeue();
    }

    return true;
  };

  return Queue;
});
